// eslint-disable-next-line no-restricted-imports
import { Group, UnstyledButton, UnstyledButtonProps, useMantineTheme } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import { MantineColor, colorToHex, getOutline } from '../inputs'
import React, { ReactNode } from 'react'
import { useTimelineCardOutlineStyle } from './TimelineCard'

export type TimelineCardProps<C = 'button'> = PolymorphicComponentProps<
  C,
  Omit<UnstyledButtonProps, 'sx' | 'styles'>
>

export function TimelineCardButton<C = 'button'>({
  leftSide,
  rightSide,
  styles,
  ...props
}: TimelineCardProps<C> & {
  leftSide: ReactNode
  rightSide?: ReactNode
  styles?: MantineColor<{
    edgeColor?: string
    backgroundColor?: string
    activeBackgroundColor?: string
    borderColor?: string
  }>
}) {
  const {
    other: { colors, sizes },
    radius,
  } = useMantineTheme()

  const {
    edgeColor,
    backgroundColor,
    activeBackgroundColor = colors.background[2],
    borderColor,
  } = colorToHex(styles, colors) ?? {}

  const { classes } = useTimelineCardOutlineStyle({ backgroundColor, borderColor })

  return (
    <UnstyledButton
      {...(props as TimelineCardProps)}
      className={classes.card}
      sx={{
        backgroundColor: colorToHex(edgeColor, colors) ?? 'unset',
        borderRadius: radius.sm,
        paddingLeft: edgeColor ? sizes.padding.md : 0,
        ':focus': {
          outline: 'none',
          '.timeline-card-title': {
            boxShadow: getOutline({
              color: colors.actions[0],
              gap: sizes.outline.sm,
              width: sizes.outline.sm,
              backgroundColor: backgroundColor ?? 'unset',
            }),
          },
        },
        ':active .timeline-card-title': {
          backgroundColor: activeBackgroundColor,
          boxShadow: 'unset',
        },
      }}
    >
      <Group
        spacing='sm'
        position='apart'
        noWrap
        sx={{
          padding: sizes.padding.lg,
          backgroundColor: backgroundColor ?? 'unset',
          borderTopRightRadius: radius.sm,
          borderBottomRightRadius: radius.sm,
        }}
      >
        {leftSide}
        {rightSide}
      </Group>
    </UnstyledButton>
  )
}
