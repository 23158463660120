import { dayjs } from './dayjs'

const OLD_PERSON_ALIVE_AGE = 112
const ADULT_AGE = 16
const DATE_LENGTH = 8

const normalizeToDigits = (value: string) => {
  return value.replace(/[^0-9+]/g, '')
}

const formatDate = (value: string) => {
  if (value.length <= 2) {
    return value
  }

  if (value.length <= 4) {
    return `${value.slice(0, 2)}/${value.slice(2)}`
  }

  return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, DATE_LENGTH)}`
}

const validateBirthday = (value: string) => {
  const date = dayjs(value, 'MM/DD/YYYY')
  if (!date.isValid() || date.format('MM/DD/YYYY') !== value) {
    return false
  }

  const today = dayjs()
  const adultAgeDateValue = today.subtract(ADULT_AGE, 'years')
  const oldestAgeDateValue = today.subtract(OLD_PERSON_ALIVE_AGE, 'years')

  if (date.isAfter(adultAgeDateValue) || date.isBefore(oldestAgeDateValue)) {
    return false
  }

  return true
}

export const date = (date?: string) => {
  if (!date) {
    return {
      formatted: '',
      isValidBirthday: false,
    }
  }

  const normalized = normalizeToDigits(date)
  const formatted = formatDate(normalized)
  const isValidBirthday = validateBirthday(formatted)

  return {
    formatted,
    isValidBirthday,
  }
}
