import { DatabaseMetadata } from '.'

export type ThankYouNoteModel = {
  fromPatientId: string
  toPatientId: string
  from: string
  message: string
  seenAt: string | null
}

export type ThankYouNote = ThankYouNoteModel & DatabaseMetadata
