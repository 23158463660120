/**
 * NOTE: Some of these types are generated.
 * For more details, see part about Codegen here:
 * https://docs.discovery.verifiable.com/#section/Getting-Started
 */

import { StateAbbr } from './api'

export type VerifiableProviderInfo = {
  BasicInfo: VerifiableProviderBasicInfo
  Caqh: VerifiableProviderCaqh | null
  Licenses: VerifiableProviderLicense[]
  DeaRegistrations: VerifiableProviderDeaRegistration[]
  BoardCertifications: VerifiableBoardCertifications[]
  Certificates: VerifiableCertificates[]
}

export type VerifiableBoardCertifications = {
  lastUpdatedAt: string
  id: string
  type: string
  firstName: string
  lastName: string
  boardCertificationNumber: string
  specialty: string
  isPrimary: boolean
  nonVerifiedInfo: {
    initialCertificationDate: string
    recertificationDate: string
    expirationDate: string
  }
}

export type VerifiableCertificates = {
  lastUpdatedAt: string
  id: string
  type: string
  firstName: string | null
  lastName: string | null
  certificateNumber: string | null
  issueDate: string | null
  expirationDate: string | null
  certifyingOrganization: string | null
}

export type VerifiableAddress = {
  type: string
  state: VerifiableState
  zipCode: string
  city: string
  addressLine1: string
}

export type VerifiableProviderBasicInfo = {
  id: string
  firstName: string
  lastName: string
  credentialingStatus: 'Credentialed' | 'Archived' | 'Data Collection'
  dateOfBirth?: string
  ssn?: string
  credentialedDate?: string
  npi?: number
  aliases?: {
    firstName: string
    lastName: string
  }[]
  addresses?: VerifiableAddress[]
  phone?: string
  languages?: string[]
  emails?: {
    email: string
    type: string
  }[]
  recredentialedDates?: string[]
  providerType?: {
    id: string
    name: VerifiableProviderTypeName
  }
}

export type VerifiableProviderTypeName =
  | 'Nurse Practitioner'
  | 'Registered Nurse'
  | 'Medical Doctor'
  | 'Physician Assistant'

export type VerifiableProviderCaqh = {
  caqhId: string
  username: string
  password: string
  lastUpdatedAt: string
}

export type VerifiableProviderDeaRegistration = {
  providerId?: string
  registrationNumber: string
  currentVerification: {
    id: string
    timestamp: string
    lastUpdated: string
    lastSync: string
    status: 'Found' | 'Failed' | 'NeedsReview' | 'NotFound' | 'Pending' | 'Working'
    originalStatus: 'Found' | 'Failed' | 'NeedsReview' | 'NotFound' | 'Pending' | 'Working'
    results?: {
      businessActivityCode: string
      drugSchedules: string[]
      expirationDate: string
      name: string
      additionalCompanyInfo: string
      address1: string
      address2: string
      city: string
      state: string
      zipCode: string
      businessActivitySubCode: string
      paymentIndicator: string
      active: boolean
      problems: string[]
      businessActivity: string
    }
    deaxResults: {
      number: string
      certifiedPatientLimit: number
    }
  }
}

export type VerifiableProviderLicense = {
  id: string
  licenseNumber: string
  state: VerifiableState
  jobStatus: 'Idle' | 'Pending' | 'Working'
  collaboratingProviderId: string
  prescriptiveAuthority: 'RequiredMet' | 'RequiredNotMet' | 'NotRequired'
  isCurrentlyPracticing: boolean
  isPrimary: string
  approvedStatus: 'Pending' | 'Yes' | 'No'
  restrictionStatus: 'None' | 'Restricted' | 'NotRestricted'
  currentVerificationStatus: 'Found' | 'Failed' | 'NeedsReview' | 'NotFound' | 'Pending' | 'Working'
  currentVerification: {
    id: string
    originalStatus: 'Found' | 'Failed' | 'NeedsReview' | 'NotFound' | 'Pending' | 'Working'
    status: 'Found' | 'Failed' | 'NeedsReview' | 'NotFound' | 'Pending' | 'Working'
    started: string
    processingTime: number
    verificationSource: { name: string; url: string }[]
    failureReason: { messageTemplate: string; messageParams: Record<string, string> }
    correctResultIndex: number
    export: string
    results?: {
      name: string
      licenseType: string
      licenseStatus: string
      licenseNumber: string
      address: string
      issued: string
      expires: string
      isExpired: boolean
      screenshots: string[]
      problems: string[]
    }[]
  }
}

export type VerifiablePage<T, S> = {
  nextOffset?: number | null
  pageSize: number
  count: number
  totalCount: number
  items: T[] | null
  sortedBy: S
  sortDirection: VerifiablePageSortDirection
}

export type VerifiableGroup = {
  id: string
  name: string
  npi?: number
  taxId?: string
}

export type VerifiablePayerPlanResponse = {
  id: string
  name?: string
  payerId?: string
  state?: VerifiableState
  displayName?: string
}

export type VerifiableProviderEnrollment = {
  id: string
  providerId: string
  groupId: string
  payerPlanId: string
  effectiveDate: string
  networkStatus: string
  specialistType: string
  enrollmentStatus: 'Denied' | 'NotSubmitted' | 'Submitted' | 'Enrolled'
  /**
   * If set, the provider enrollment's ProviderId in external system will be updated with this value.
   */
  externalProviderPlanId?: string
}

export type VerifiableProviderEnrollmentPage = VerifiablePage<
  VerifiableProviderEnrollment,
  VerifiableProviderEnrollmentPageSortedBy
>

export type VerifiableProviderPage = VerifiablePage<
  VerifiableProviderBasicInfo,
  VerifiableProviderPageSortedBy
>

export type VerifiableProviderPageSortedBy =
  | 'Id'
  | 'LastName'
  | 'FirstName'
  | 'CredentialingStatus'
  | 'CredentialedDate'

export type VerifiableProviderEnrollmentPageSortedBy =
  | 'Id'
  | 'NetworkStatus'
  | 'EnrollmentStatus'
  | 'SpecialistType'

export type VerifiablePageSortDirection = 'Asc' | 'Desc'

export type VerifiableState = StateAbbr
