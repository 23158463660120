import { Day, YYYYMMDD } from '..'
import { DatabaseMetadata } from '../models'
import { Employee } from '../models/employee'

export type HourValue = `${number}:${number}`
export type HourRange = {
  start: HourValue
  end: HourValue
}

export type HourRanges = Record<Day, HourRange[]>

export type CalendarDayModel = {
  employeeId: Employee['oid']
  date: YYYYMMDD
  workingHours: HourRange[]
}

export type CalendarDay = CalendarDayModel & DatabaseMetadata
