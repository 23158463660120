export type FormContentType = 'label'

export namespace FormContentSchema {
  export type NoType<C extends FormContentType = FormContentType> = Omit<
    FormContentSchema<C>,
    'type'
  >
}

export type FormContentSchema<C extends FormContentType = FormContentType> = C extends 'label'
  ? {
      type: C
      primaryText: string
      secondaryText: string
    }
  : never
