export type PaymentDownload = {
  download_url: string
  valid_till: number
  mime_type?: string
}

export type UpdatePaymentCard = {
  first_name?: string
  last_name?: string
  expiry_month?: number
  expiry_year?: number
  billing_addr1?: string
  billing_addr2?: string
  billing_city?: string
  billing_zip?: string
  billing_state_code?: string
  billing_state?: string
  billing_country?: string
}

export type CreatePaymentCard = {
  gateway_account_id?: string
  first_name?: string
  last_name?: string
  number: string
  expiry_month: number
  expiry_year: number
  cvv?: string
  billing_addr1?: string
  billing_addr2?: string
  billing_city?: string
  billing_state_code?: string
  billing_state?: string
  billing_zip?: string
  billing_country?: string
  additional_information?: object
}

export type PaymentCard = {
  first_name?: string
  last_name?: string
  iin: string
  last4: string
  brand: string
  funding_type: string
  expiry_month: number
  expiry_year: number
  billing_addr1?: string
  billing_addr2?: string
  billing_city?: string
  billing_state_code?: string
  billing_state?: string
  billing_country?: string
  billing_zip?: string
  masked_number?: string
}

export type PaymentInvoice = {
  id: string
  customer_id: string
  recurring: boolean
  status: 'paid' | 'posted' | 'payment_due' | 'not_paid' | 'voided' | 'pending'
  price_type: 'tax_exclusive' | 'tax_inclusive'
  currency_code: string
  sub_total: number
  tax: number
  term_finalized: boolean
  deleted: boolean
  business_entity_id: string
  expected_payment_date?: number
  amount_to_collect?: number
  due_date?: number
  amount_paid?: number
  voided_at?: number
  amount_due: number
  adjustment_credit_notes: { cn_reason_code: 'write_off'; cn_date: number; cn_total: number }[]
}

export type PaymentPaymentSource = {
  id: string
  created_at: number
  customer_id: string
  card?: PaymentCard
  type:
    | 'card'
    | 'paypal_express_checkout'
    | 'amazon_payments'
    | 'direct_debit'
    | 'generic'
    | 'alipay'
    | 'unionpay'
    | 'apple_pay'
    | 'wechat_pay'
    | 'ideal'
    | 'google_pay'
    | 'sofort'
    | 'bancontact'
    | 'giropay'
    | 'dotpay'
    | 'upi'
    | 'netbanking_emandates'
  reference_id: string
  status: 'valid' | 'expiring' | 'expired' | 'invalid' | 'pending_verification'
  gateway: ChargebeeGateway
  deleted: boolean
}

export type ChargebeeGateway =
  | 'chargebee'
  | 'chargebee_payments'
  | 'stripe'
  | 'wepay'
  | 'braintree'
  | 'authorize_net'
  | 'paypal_pro'
  | 'pin'
  | 'eway'
  | 'eway_rapid'
  | 'worldpay'
  | 'balanced_payments'
  | 'beanstream'
  | 'bluepay'
  | 'elavon'
  | 'first_data_global'
  | 'hdfc'
  | 'migs'
  | 'nmi'
  | 'ogone'
  | 'paymill'
  | 'paypal_payflow_pro'
  | 'sage_pay'
  | 'tco'
  | 'wirecard'
  | 'amazon_payments'
  | 'paypal_express_checkout'
  | 'gocardless'
  | 'adyen'
  | 'orbital'
  | 'moneris_us'
  | 'moneris'
  | 'bluesnap'
  | 'cybersource'
  | 'vantiv'
  | 'checkout_com'
  | 'paypal'
  | 'ingenico_direct'
  | 'exact'
  | 'mollie'
  | 'quickbooks'
  | 'razorpay'
  | 'not_applicable'

export type ChargebeePaymentSubscriptionStatus =
  | 'future'
  | 'in_trial'
  | 'active'
  | 'non_renewing'
  | 'paused'
  | 'cancelled'

export type ChargebeePaymentSubscription = {
  subscription: {
    id: string
    currency_code: string
    customer_id: string
    status: ChargebeePaymentSubscriptionStatus
    has_scheduled_advance_invoices: boolean
    has_scheduled_changes: boolean
    deleted: boolean
  }
}

export type ChargebeeCustomer = {
  id: string
  email?: string
  firstName?: string
  lastName?: string
  createdAt?: string
  updatedAt?: string
  billingAddress?: {
    city?: string
    first_name?: string
    last_name?: string
    line1?: string
    state?: string
    zip?: string
  }
}

export type ListSubscriptionsForCustomerResponse = {
  list: ChargebeePaymentSubscription[]
}

export type CreateSubscriptionForCustomerResponse = {
  customer: {
    id: string
  }
  subscription: {
    id: string
  }
}

export type PaymentTransaction = {
  id: string
  amount: number
  status?: PaymentTransactionStatus
  deleted: boolean
  subscription_id: string
  linked_invoices: {
    applied_amount: number
    applied_at: number
    invoice_date: number
    invoice_id: string
    invoice_status: string
    invoice_total: number
  }[]
}

export type PaymentTransactionStatus =
  | 'in_progress'
  | 'success'
  | 'voided'
  | 'failure'
  | 'timeout'
  | 'needs_attention'
