export type ShipmentStatus = 'ordered' | 'shipped' | 'canceled' | 'failed'

export type ShippingPartner = 'easypost' | 'truepill' | 'shipfusion'

export type ShipmentItems = {
  sku: string
  quantity: number
}[]

export type ShipmentTrackingInfo = {
  shippingDate: string
  trackingNumber: string
  shippingCarrier: string
}[]
