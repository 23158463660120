const PATTERN = /[^A-Za-z0-9]/g

export const matchesSearch = ({
  itemValue,
  searchValue,
}: {
  itemValue: string | string[]
  searchValue: string
}) => {
  const input = searchValue.toLowerCase().replace(PATTERN, '')
  const itemValues = Array.isArray(itemValue) ? itemValue : [itemValue]
  return itemValues.some(itemValue => itemValue.toLowerCase().replace(PATTERN, '').includes(input))
}
