// eslint-disable-next-line no-restricted-imports
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  useMantineTheme,
} from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import React, { PropsWithRef } from 'react'
import {
  MantineColor,
  colorToHex,
  getAttributes,
  getBorder,
  getOutlineFocused,
  getOutlinePressed,
} from '../inputs'
import { ButtonChildren, ButtonProps } from './buttons.helpers'

type SecondaryButtonProps<T extends ButtonChildren = string, C = 'div'> = PolymorphicComponentProps<
  C,
  ButtonProps<T> & {
    size?: 'xs' | 'sm' | 'md'
    color?: MantineColor
    backgroundColor?: MantineColor
  }
>

function SecondaryButtonInner<T extends ButtonChildren, C = 'div'>(
  {
    size = 'md',
    fullWidth,
    children,
    radius: buttonRadius,
    disabled,
    color,
    backgroundColor,
    ...props
  }: SecondaryButtonProps<T, C>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    other: { colors, fontFamilies, sizes },
    radius,
    spacing,
    fontSizes,
  } = useMantineTheme()

  const height = sizes.button[size]
  const px = size === 'md' ? spacing.md : spacing.sm

  const borderRadius =
    typeof buttonRadius === 'string' ? radius[buttonRadius]! : buttonRadius ?? radius.sm

  return (
    <MantineButton
      ref={ref}
      variant='outline'
      p='unset'
      h={height}
      miw={height}
      w={fullWidth ? '100%' : 'fit-content'}
      bg='unset'
      fz={fontSizes[size]}
      ff={fontFamilies.bold}
      lh='normal'
      radius={borderRadius}
      disabled={Boolean(disabled || props.loading)}
      {...(props as MantineButtonProps)}
      {...getAttributes({ ['data-icon']: !children })}
      loaderProps={{ color: colors.text[0], size: sizes.icon.md }}
      styles={{
        rightIcon: { margin: 'unset' },
        leftIcon: { margin: 'unset' },
        inner: {
          gap: spacing.sm,
          paddingLeft: px,
          paddingRight: px,
          borderRadius,
          boxShadow: getBorder({ width: sizes.border.md, color: colors.actions[0] }),
        },
        label: { display: children ? 'flex' : 'none' },
        root: {
          color: colorToHex(color, colors) ?? colors.text[0],
          border: 'unset',
          ':focus': {
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes, backgroundColor }),
          },
          ':before': { display: 'none' },
          ':hover': {
            backgroundColor: 'unset',
            '> div': { boxShadow: `0 0 0 ${sizes.border.md} ${colors.actions[1]} inset` },
          },
          ':active': {
            transform: 'unset',
            boxShadow: getOutlinePressed({ colors, sizes, backgroundColor }),
          },
          ':disabled': {
            backgroundColor: 'unset',
            '> div': { boxShadow: `0 0 0 ${sizes.border.md} ${colors.actions[3]} inset` },
            color: colors.actions[3],
          },
          '&[data-icon]': {
            '.mantine-Button-inner': { padding: 0 },
            '.mantine-Button-leftIcon': {
              margin: 'unset',
            },
          },
        },
      }}
    >
      {children}
    </MantineButton>
  )
}

// Type assertion to allow passing a generic into forwardRef
export const SecondaryButton = React.forwardRef<HTMLButtonElement, SecondaryButtonProps>(
  SecondaryButtonInner,
) as <T extends ButtonChildren, C = 'div'>(
  props: PropsWithRef<SecondaryButtonProps<T, C>>,
) => ReturnType<typeof SecondaryButtonInner>
