export const emergencyContactReasonings = [
  'medical_emergency',
  'no_contact_24_hours',
  'no_contact_48_hours',
  'as_determined',
] as const

export type EmergencyContactReasoning = (typeof emergencyContactReasonings)[number]

export type PatientEmergencyContact = {
  firstName?: string
  lastName?: string
  fullName?: string
  updatedAt?: string
  phoneNumber: string
  relationship: string
  reasoning?: EmergencyContactReasoning[]
}
