import { ISOString } from '../dates'
import { DatabaseMetadata } from '../models'

export type COCMRegistryItemModel = {
  dateAdded: ISOString
  patientId: string
  // Optional because this gets set when the patient is assigned to an NCM via a subscriber
  employeeId?: string
  deleted: boolean
  deletedAt?: ISOString
  needsReview: boolean
  addedBy?: string
}

export type COCMRegistryItem = COCMRegistryItemModel & DatabaseMetadata
