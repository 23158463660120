// eslint-disable-next-line no-restricted-imports
import {
  CSSObject,
  Input as MantineInput,
  InputWrapperProps as MantineInputWrapperProps,
} from '@mantine/core'
import React, { PropsWithChildren, ReactNode, useMemo } from 'react'
import { getAttributes } from './inputs'

export type InputError = {
  error?: string | boolean | undefined
  warning?: string | boolean | undefined
  success?: string | boolean | undefined
  explanation?: string | boolean | undefined
}

export type InputWrapperProps = {
  'test-id'?: string | undefined
  label?: ReactNode
  disabled?: boolean
} & InputError &
  Omit<MantineInputWrapperProps, 'labelElement' | 'styles' | 'description'>

export const InputWrapper = ({
  disabled = false,
  warning,
  success,
  error,
  explanation,
  children,
  ...props
}: PropsWithChildren<InputWrapperProps>) => {
  return (
    <MantineInput.Wrapper
      {...props}
      {...getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      labelElement='div'
      error={error ?? success ?? warning ?? explanation}
      styles={useMemo(
        () =>
          ({ other: { colors, fontFamilies, sizes }, fontSizes }) => {
            const disabledColor = colors.actions[3]

            return {
              label: { width: '100%', fontSize: fontSizes.md, marginBottom: sizes.gap.md },
              root: {
                alignSelf: 'normal',
                '&[disabled] .mantine-InputWrapper-label': { color: disabledColor },
                '&[disabled] .mantine-InputWrapper-error': {
                  color: disabledColor,
                } as CSSObject,
                '&[data-warning] .mantine-InputWrapper-error': {
                  color: colors.warning[0],
                } as CSSObject,
                '&[data-success] .mantine-InputWrapper-error': {
                  color: colors.success[0],
                } as CSSObject,
                '&[data-error] .mantine-InputWrapper-error': {
                  color: colors.error[0],
                } as CSSObject,
              },
              error: {
                lineHeight: 'normal',
                marginTop: sizes.gap.md,
                fontFamily: fontFamilies.bold,
                fontSize: fontSizes.sm,
                color: colors.text[1],
              },
            }
          },
        [],
      )}
    >
      {children}
    </MantineInput.Wrapper>
  )
}
