// eslint-disable-next-line no-restricted-imports
import { Title as MantineTitle, TitleProps as MantineTitleProps } from '@mantine/core'
import React from 'react'
import { MantineColor, colorToHex } from '../inputs'

type TitleProps = Omit<MantineTitleProps, 'order' | 'color' | 'sx'> & {
  color?: MantineColor
}

export const TitleOne = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={1}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color: colorToHex(color, colors) ?? colors.text[0],
          letterSpacing: '-0.01em',
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleTwo = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={2}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color: colorToHex(color, colors) ?? colors.text[0],
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleThree = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={3}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color: colorToHex(color, colors) ?? colors.text[0],
          letterSpacing: '0.02em',
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleFour = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={4}
      sx={[
        ({ other: { colors, fontFamilies } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color: colorToHex(color, colors) ?? colors.text[0],
          fontFamily: fontFamilies.bold,
          textTransform: 'uppercase',
          letterSpacing: '0.02em',
        }),
      ]}
      {...rest}
    />
  )
}
