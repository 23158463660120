// eslint-disable-next-line no-restricted-imports
import { Divider as MantineDivider, DividerProps as MantineDividerProps } from '@mantine/core'
import React from 'react'
import { MantineColor, colorToHex } from './inputs'

export type DividerProps = Omit<MantineDividerProps, 'color'> & { color?: MantineColor }

export const Divider = ({ sx, color, ...rest }: DividerProps) => {
  return (
    <MantineDivider
      sx={[
        ({ other: { colors, sizes } }) => {
          const defaultColor = colors.background[1]
          const borderColor = colorToHex(color, colors) ?? defaultColor

          return {
            borderRadius: sizes.border.md,
            borderWidth: sizes.border.md,
            borderColor,
          }
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  )
}
