export function zip(zip: string | undefined) {
  const zipNumbers = (zip ?? '').replace(/\D/g, '')
  const zipCode = zipNumbers.length >= 5 ? zipNumbers.substring(0, 5) : ''
  // eslint-disable-next-line no-magic-numbers
  const addonCode = zipNumbers.length >= 9 ? zipNumbers.substring(5) : ''

  return {
    zipCode,
    addonCode,
    zipPlusFourCode: zipCode && addonCode ? `${zipCode}-${addonCode}` : zipCode,
  }
}
