// eslint-disable-next-line no-restricted-imports
import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import React from 'react'
import { InputError } from '../InputWrapper'
import { Formatter, getAttributes, useFormatter, useInputThemeStyles } from './inputs.helpers'

export type TextInputProps = Omit<
  MantineTextInputProps,
  'onChange' | 'error' | 'styles' | 'sx' | 'variant' | 'description'
> &
  InputError & {
    formatter?: Formatter
    onChange?: (value: string) => void
  }

export const TextInput = ({
  error,
  warning,
  success,
  disabled = false,
  onChange,
  formatter,
  value,
  rightSection,
  rightSectionWidth,
  explanation,
  ...props
}: TextInputProps) => {
  const { formatValue, onChangeFormat } = useFormatter({ formatter, onChange })

  const {
    styles,
    inputStyles,
    iconColor,
    theme: {
      other: { sizes },
    },
  } = useInputThemeStyles()

  return (
    <MantineTextInput
      {...props}
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      error={error ?? success ?? warning ?? explanation}
      rightSection={rightSection}
      onChange={onChangeFormat}
      disabled={disabled}
      value={formatValue(value)}
      styles={{
        rightSection: { width: 'unset', marginRight: sizes.padding.md, color: iconColor },
        input: {
          paddingRight: rightSection
            ? `calc(${rightSectionWidth ?? sizes.icon.md} + ${sizes.padding.md} + ${sizes.gap.md})`
            : sizes.padding.md,
          ...inputStyles,
        },
        ...styles,
      }}
    />
  )
}
