// eslint-disable-next-line no-restricted-imports
import { Menu as MantineMenu, MenuProps as MantineMenuProps } from '@mantine/core'
import React from 'react'

type MenuProps = Omit<MantineMenuProps, 'styles' | 'sx'>

export function Menu(props: MenuProps) {
  return (
    <MantineMenu
      {...props}
      offset={4}
      withinPortal
      styles={({ other: { colors, sizes }, radius, fontSizes }) => ({
        dropdown: {
          padding: '0px!important',
          backgroundColor: colors.background[0],
          borderColor: colors.actions[0],
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          minWidth: '8rem',
        },
        divider: {
          margin: 0,
          borderColor: colors.actions[0],
          borderWidth: sizes.border.md,
        },
        item: {
          fontSize: fontSizes.md,
          color: colors.text[0],
          paddingTop: sizes.padding.md,
          paddingBottom: sizes.padding.md,
          paddingLeft: sizes.gap.md,
          paddingRight: sizes.gap.md,
          ':not(:first-of-type), :not(:last-of-type)': {
            borderRadius: 0,
          },
          ':first-of-type': {
            borderTopLeftRadius: radius.xs,
            borderTopRightRadius: radius.xs,
          },
          ':last-of-type': {
            borderBottomStyle: 'none',
            borderBottomLeftRadius: radius.xs,
            borderBottomRightRadius: radius.xs,
          },
          ':hover': { backgroundColor: colors.background[2] },
          '&:focus, &:active': {
            outline: 'none',
            color: colors.text[0],
            backgroundColor: colors.actions[0],
          },
        },
      })}
    />
  )
}

export namespace Menu {
  export const { Target } = MantineMenu
  export const { Item } = MantineMenu
  export const { Divider } = MantineMenu
  export const { Label } = MantineMenu
  export const { Dropdown } = MantineMenu
}
