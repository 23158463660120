export class SimpleCache<D> {
  _ttl_ms: number
  _cache: Map<string, { cachedAt: number; data: D | undefined }>

  constructor(arg: { ttl_ms: number }) {
    this._ttl_ms = arg.ttl_ms
    this._cache = new Map()
  }

  set = (key: string, value: D) => {
    this._cache.set(key, { cachedAt: Date.now(), data: value })
  }

  get = (key: string): D | undefined => {
    const cacheEntry = this._cache.get(key)

    if (!cacheEntry) {
      return undefined
    }

    const { cachedAt, data } = cacheEntry

    if (cachedAt + this._ttl_ms < Date.now()) {
      this._cache.delete(key)
      return undefined
    }

    return data
  }
}
