// eslint-disable-next-line no-restricted-imports
import {
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps,
  MantineSize,
} from '@mantine/core'
import React, { ReactNode } from 'react'
import { useInlineInputThemeStyles } from '../inline-inputs.helpers'

export type CheckboxProps = Omit<
  MantineCheckboxProps,
  'styles' | 'icon' | 'label' | 'description' | 'radius' | 'value' | 'fz'
> & {
  label: ReactNode
  radius?: MantineSize
  value?: string
  fz?: MantineSize
  iconless?: boolean
  description?: string
}

export const Checkbox = ({
  disabled = false,
  value,
  checked,
  radius,
  fz = 'md',
  iconless,
  description,
  ...props
}: CheckboxProps) => {
  const { styles, inputStyle } = useInlineInputThemeStyles({
    scale: 1 / 2,
    iconless: Boolean(iconless),
    description: Boolean(description),
    iconRadius: 'sm',
    inputRadius: radius,
    fz,
  })

  return (
    <MantineCheckbox
      {...props}
      /*
       * Adds data-checked attribute to root in order to style labels,
       * this is already added by the CheckboxGroup but not by checked prop.
       */
      wrapperProps={checked ? { ['data-checked']: true } : {}}
      checked={checked}
      value={value}
      disabled={disabled}
      description={description}
      styles={{ input: inputStyle, ...styles }}
    />
  )
}
