// eslint-disable-next-line no-restricted-imports
import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
} from '@mantine/core'
import React from 'react'
import { getAttributes, useInputThemeStyles } from '.'
import { InputError } from '../InputWrapper'

export type NumberInputProps = Omit<MantineNumberInputProps, 'error' | 'variant' | 'description'> &
  InputError & {
    /*
     * We exclude 'revert-layer' from the TextAlign type here beacuse
     * our React's CSSProperties are newer and include some CSS property values that the
     * currently installed Mantine's React version's CSSProperties does not yet support
     */
    textAlign?: Exclude<React.CSSProperties['textAlign'], 'revert-layer'>
  }

export const NumberInput = ({
  error,
  warning,
  success,
  disabled = false,
  textAlign = 'unset',
  hideControls = true,
  explanation,
  ...props
}: NumberInputProps) => {
  const {
    styles,
    inputStyles,
    theme: {
      other: { sizes, colors },
    },
  } = useInputThemeStyles()

  return (
    <MantineNumberInput
      {...props}
      type='number'
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      error={error ?? success ?? warning ?? explanation}
      disabled={disabled}
      hideControls={hideControls}
      styles={{
        rightSection: {
          width: sizes.icon.lg,
          marginRight: sizes.border.md,
          paddingTop: sizes.border.md,
          paddingBottom: sizes.border.md,
          borderColor: colors.actions[0],
          '> div': {
            borderWidth: 0,
            borderLeftWidth: sizes.border.md,
            borderStyle: 'solid',
            borderColor: 'inherit',
            margin: 'unset',
            height: '100%',
          },
        },
        control: {
          margin: 'unset',
          width: 'unset',
          borderLeft: 'unset',
          borderColor: colors.actions[0],
          '& svg': {
            height: sizes.icon.sm,
            width: sizes.icon.sm,
            color: `${colors.actions[0]}!important`,
          },
        },
        input: {
          textAlign,
          paddingRight: hideControls ? sizes.padding.md : sizes.icon.lg + sizes.gap.md,
          ...inputStyles,
        },
        ...styles,
      }}
    />
  )
}
