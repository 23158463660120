/* eslint-disable no-magic-numbers */
import { PluginFunc } from 'dayjs'

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Dayjs {
    getDay(): 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
  }
}

/**
 * Gets the day of the week as a string.
 */
const getDay: PluginFunc<unknown> = (option, dayjsClass) => {
  dayjsClass.prototype.getDay = function () {
    switch (this.day()) {
      case 0:
        return 'sunday'
      case 1:
        return 'monday'
      case 2:
        return 'tuesday'
      case 3:
        return 'wednesday'
      case 4:
        return 'thursday'
      case 5:
        return 'friday'
      case 6:
        return 'saturday'
      default:
        return 'sunday'
    }
  }
}

export default getDay
