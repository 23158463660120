export const url = (config: {
  base: string
  pathname?: string
  searchParams?: Record<string, string>
}) => {
  const _url = config.pathname ? new URL(config.pathname, config.base) : new URL(config.base)

  if (config.searchParams) {
    for (const [key, value] of Object.entries(config.searchParams)) {
      _url.searchParams.set(key, value)
    }
  }

  return _url
}
