import dayjs, { PluginFunc } from 'dayjs'

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Dayjs {
    isWithinLast(value: number, unit: dayjs.ManipulateType): boolean
  }
}

/**
 * Gets the days or months between two dates (inclusive of both dates).
 */
const isWithinLast: PluginFunc<unknown> = (option, dayjsClass) => {
  dayjsClass.prototype.isWithinLast = function (value: number, unit: dayjs.ManipulateType) {
    const now = dayjs()
    // The fourth parameter, `[]` indicates that both the beginning and end of the range are inclusive
    return this.isBetween(now.subtract(value, unit), now, null, '[]')
  }
}

export default isWithinLast
