import React, { SVGAttributes } from 'react'

export const OpheliaWordmark = (props: SVGAttributes<SVGElement>) => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 82 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M81.2709 17.539C81.5002 17.539 81.7037 17.6916 81.7037 17.9458C81.7037 18.4293 80.9148 19.3704 79.7957 19.3704C78.9568 19.3704 78.0153 18.8616 77.8626 17.3609C77.8626 17.3101 77.8626 17.2337 77.8117 17.2337C77.7609 17.2337 77.7609 17.2591 77.7101 17.3354C76.7943 18.7091 75.2172 19.3704 73.6401 19.3704C71.1727 19.3704 69.6719 17.8442 69.6719 16.089C69.6719 15.0715 70.1806 14.0032 71.2489 13.3163C72.5717 12.426 74.1488 12.1463 75.5479 11.4848C76.5654 11.0269 77.6846 10.2893 77.6846 8.9157C77.6846 7.69476 76.8451 6.82991 75.4716 6.82991C73.9454 6.82991 73.1568 7.82194 72.5209 8.71223C72.0122 9.42447 71.9358 9.88237 71.3762 9.88237C70.7656 9.88237 70.664 9.2973 70.664 8.63592C70.664 7.97462 70.7912 7.16048 71.0964 6.77896C71.7832 5.88867 73.8436 5.53259 75.2935 5.53259C78.5498 5.53259 80.2285 7.36403 80.2285 9.88237C80.2285 11.8664 80.1518 14.2067 80.1518 16.2416C80.1518 17.1318 80.457 17.539 80.9657 17.539H81.2709ZM77.6846 11.9174C77.6846 11.8664 77.6592 11.8156 77.6083 11.8156C77.5574 11.8156 77.532 11.841 77.4302 11.9174C76.5399 12.6041 74.8611 13.0366 73.6401 13.8506C72.7243 14.4611 72.3683 15.2495 72.3683 15.9619C72.3683 16.9793 73.055 17.895 74.4032 17.895C75.3952 17.895 76.5399 17.3354 77.1504 16.089C77.6592 15.0207 77.6846 13.9014 77.6846 12.3498V11.9174'
      fill='currentColor'
    />
    <path
      d='M45.151 14.6137C45.151 15.9364 45.2274 16.903 45.5326 17.3863C45.965 18.0477 46.779 18.1494 46.779 18.6073C46.779 18.9634 46.4484 19.0906 46.0668 19.0906H41.7934C41.4118 19.0906 41.0811 18.9634 41.0811 18.6073C41.0811 18.0986 41.946 18.0732 42.353 17.2846C42.6073 16.8013 42.6836 15.8855 42.6836 14.6137V10.6455C42.6836 8.40703 41.5135 7.46587 40.0128 7.46587C39.2497 7.46587 38.4356 7.74565 37.8251 8.35616C36.8585 9.32268 36.6296 10.6964 36.6296 14.6137C36.6296 15.911 36.7059 16.8522 36.9857 17.3354C37.4182 18.0732 38.2321 18.1494 38.2321 18.6073C38.2321 18.9634 37.9269 19.0906 37.5454 19.0906H33.3228C32.9412 19.0906 32.636 18.9634 32.636 18.6073C32.636 18.0732 33.5516 18.0477 33.9332 17.2337C34.1622 16.725 34.2385 15.8601 34.2385 14.6137V4.18434C34.2385 3.37043 34.2131 2.91253 33.9841 2.58196C33.5771 1.97137 32.5088 2.09855 32.5088 1.53899C32.5088 1.25913 32.636 1.08109 33.221 0.953912C34.1622 0.750357 34.9253 0.496011 35.5866 0.190718C35.7647 0.114414 35.9173 0.0635446 36.07 0.0635446C36.4007 0.0635446 36.6296 0.292456 36.6296 0.77587V7.74565C36.6296 7.82196 36.6551 7.87283 36.7059 7.87283C36.7568 7.87283 36.7823 7.82196 36.8331 7.74565C37.6216 6.57558 39.0461 5.53261 40.9539 5.53261C42.4039 5.53261 43.5994 6.14304 44.2863 7.10963C44.8457 7.87283 45.151 8.78855 45.151 10.5692V14.6137'
      fill='currentColor'
    />
    <path
      d='M57.9205 16.3434C57.9205 17.0557 55.9873 19.3704 52.6041 19.3704C48.7122 19.3704 46.4229 16.3181 46.4229 12.833C46.4229 9.11928 49.0683 5.53253 53.011 5.53253C56.6741 5.53253 58.2003 8.50877 58.2003 10.2893C58.2003 11.841 57.4372 12.0699 56.1907 12.0954H49.2973C49.1192 12.0954 49.0174 12.1717 49.0174 12.3498V12.477C49.0174 15.1478 50.5436 17.5389 53.24 17.5389C55.7328 17.5389 56.7503 15.8093 57.3608 15.8093C57.666 15.8093 57.9205 15.9873 57.9205 16.3434V16.3434ZM49.1192 10.6709C49.1192 10.798 49.1701 10.8236 49.3226 10.8236H53.4689C54.9443 10.8236 55.6057 10.5183 55.6057 9.34812C55.6057 8.17812 54.5881 6.77907 52.7821 6.77907C50.7471 6.77907 49.4498 8.50877 49.1446 10.3911C49.1192 10.5946 49.1192 10.6455 49.1192 10.6709'
      fill='currentColor'
    />
    <path
      d='M62.194 17.2337C62.6518 18.1239 63.4913 18.0731 63.4913 18.6072C63.4913 18.9635 63.1605 19.0906 62.779 19.0906H58.5309C58.1493 19.0906 57.8441 18.9635 57.8441 18.6072C57.8441 18.0731 58.7344 18.0476 59.1415 17.2337C59.3958 16.7249 59.4467 15.8855 59.4467 14.6391V4.66773C59.4467 3.57389 59.4467 2.98881 59.1923 2.58186C59.0398 2.32751 58.7344 2.17483 58.3529 2.04765C57.9968 1.92048 57.7424 1.79323 57.7424 1.53888C57.7424 1.28454 57.8441 1.08098 58.4545 0.953809C59.3958 0.75041 60.1081 0.546934 60.8712 0.216206C61.0492 0.139824 61.2018 0.0889543 61.3545 0.0889543C61.6597 0.0889543 61.8886 0.292509 61.8886 0.75041V14.6391C61.8886 15.9109 61.9395 16.7249 62.194 17.2337'
      fill='currentColor'
    />
    <path
      d='M68.4256 17.2337C68.8835 18.1239 69.723 18.0731 69.723 18.6072C69.723 18.9635 69.3923 19.0906 69.0107 19.0906H64.7627C64.3812 19.0906 64.0759 18.9635 64.0759 18.6072C64.0759 18.0731 64.9663 18.0476 65.3732 17.2337C65.6276 16.7249 65.6784 15.8855 65.6784 14.6391V10.1112C65.6784 9.01744 65.6784 8.43244 65.4241 8.02541C65.2715 7.77106 64.9663 7.61838 64.5847 7.4912C64.2285 7.36395 63.9741 7.23678 63.9741 6.98243C63.9741 6.72809 64.0759 6.52461 64.6864 6.39744C65.6276 6.19396 66.3399 5.99041 67.103 5.65968C67.281 5.58337 67.4337 5.53251 67.5862 5.53251C67.8915 5.53251 68.1204 5.73606 68.1204 6.19396V14.6391C68.1204 15.9109 68.1713 16.7249 68.4256 17.2337V17.2337ZM68.6546 1.80591C68.6546 2.80349 67.846 3.61208 66.8485 3.61208C65.851 3.61208 65.0425 2.80349 65.0425 1.80591C65.0425 0.808559 65.851 -2.66567e-05 66.8485 -2.66567e-05C67.846 -2.66567e-05 68.6546 0.808559 68.6546 1.80591'
      fill='currentColor'
    />
    <path
      d='M9.37362 0.623164C4.19674 0.623164 0 4.81987 0 9.99679C0 15.1737 4.19674 19.3704 9.37362 19.3704C14.5505 19.3704 18.7472 15.1737 18.7472 9.99679C18.7472 4.81987 14.5505 0.623164 9.37362 0.623164ZM9.37362 17.9967C5.75617 17.9967 2.82358 14.4151 2.82358 9.99679C2.82358 5.57845 5.75617 1.99687 9.37362 1.99687C12.991 1.99687 15.9237 5.57845 15.9237 9.99679C15.9237 14.4151 12.991 17.9967 9.37362 17.9967Z'
      fill='currentColor'
    />
    <path
      d='M27.574 5.53261C25.9206 5.53261 24.4198 6.4229 23.4022 7.66927C23.3005 7.79644 23.2497 7.79644 23.1988 7.79644C23.1225 7.79644 23.0716 7.72022 23.0716 7.59304V6.19399C23.0716 5.73608 22.8681 5.53261 22.5629 5.53261C22.4103 5.53261 22.2322 5.58348 22.0542 5.65978C21.2911 5.99035 20.6042 6.19399 19.663 6.39746C19.078 6.52464 18.9507 6.70268 18.9507 6.93167C18.9507 7.56761 19.9683 7.36406 20.4007 8.02543C20.6551 8.43239 20.6551 8.91564 20.6551 9.85696V19.5485C20.6551 20.7186 20.6042 21.558 20.4261 22.0413C20.1208 22.9317 19.0525 22.9571 19.0525 23.5167C19.0525 23.8728 19.3832 24 19.7648 24H23.9873C24.3689 24 24.6741 23.8473 24.6741 23.4912C24.6741 22.9317 23.7838 23.008 23.3769 22.143C23.1479 21.6598 23.0716 20.8204 23.0716 19.6248V18.4293C23.0716 18.3529 23.1225 18.3021 23.1734 18.3021C23.3769 18.3021 24.5724 19.3704 26.7092 19.3704C30.7028 19.3704 32.7885 15.3259 32.7885 11.8156C32.7885 7.89826 30.4485 5.53261 27.574 5.53261V5.53261ZM26.6837 18.0986C24.6888 18.0986 23.0716 15.6956 23.0716 12.7312C23.0716 9.76704 24.6888 7.36406 26.6837 7.36406C28.6786 7.36406 30.2958 9.76704 30.2958 12.7312C30.2958 15.6956 28.6786 18.0986 26.6837 18.0986'
      fill='currentColor'
    />
  </svg>
)
