// eslint-disable-next-line no-restricted-imports
import { SelectItem } from '@mantine/core'
import { date, dayjs, phone } from '@shared/utils'

export type FormPredicate<T, V> = (value?: T, values?: V) => string | undefined
export type FormValuePredicate<T> = (value?: T) => string | undefined
export type FormSkipPredicate<T, V> = (value?: T, values?: V) => boolean

/**
 * Combines multiple InputPredicate for an input validation.
 * A single Predicate can stop the validations if return value is true.
 */
export function validateWith<T, V>(
  ...args: ((value?: T, values?: V) => unknown)[]
): FormPredicate<T, V> {
  return (value, values) => {
    for (const item of args) {
      const result = item(value, values)
      if (typeof result === 'boolean' && result) {
        return
      } else if (typeof result === 'string') {
        return result
      }
    }
  }
}

export const skipIfEmpty = <T>(value: T): boolean => !value

export const skipIfEmptyPhone = <T>(value: T): boolean => {
  return !value || value === '+1 '
}

type ArrayType<T> = T extends (infer E)[] ? E : never

export function skipIfOtherField<V, K extends keyof V>(
  key: K,
  op: V[K] extends string | boolean | null | undefined
    ? 'is' | 'not'
    : ArrayType<V[K]> extends string | undefined
    ? 'includes' | 'omit'
    : never,
  value: V[K] extends string | undefined
    ? V[K] | V[K][]
    : ArrayType<V[K]> extends string | undefined
    ? V[K]
    : never,
) {
  return (_?: unknown, values?: V): boolean => {
    const fieldValue = values?.[key]

    switch (op) {
      case 'is':
        if (Array.isArray(value)) {
          return value.some(item => item === fieldValue)
        }

        return fieldValue === value
      case 'not':
        if (Array.isArray(value)) {
          return !value.some(item => item === fieldValue)
        }

        return fieldValue !== value
      case 'includes': {
        if (Array.isArray(fieldValue) && Array.isArray(value)) {
          return value.every(item => fieldValue.includes(item))
        }

        return false
      }
      case 'omit': {
        if (Array.isArray(fieldValue) && Array.isArray(value)) {
          return !value.every(item => fieldValue.includes(item))
        }

        return true
      }
      default:
        return false
    }
  }
}

export const isAmountWords: <T extends string>(
  args: { amount: number; op: '>=' },
  message: string,
) => FormValuePredicate<T> =
  ({ amount, op }, message) =>
  value => {
    const words = value
      ?.trim()
      ?.split(' ')
      .filter(value => value?.match(/[A-Za-z]+/))

    switch (op) {
      case '>=': {
        if ((words?.length ?? 0) < amount) {
          return message
        }

        break
      }
    }
  }

export const isAnySelected: <T extends string | string[] | null>(
  data: readonly (string | SelectItem)[],
  message: string,
) => FormValuePredicate<T> = (data, message) => value =>
  data.some(item => {
    const itemValue = typeof item === 'string' ? item : item.value
    return Array.isArray(value) ? value.includes(itemValue) : itemValue === value
  })
    ? undefined
    : message

export const isAllSelected: <T extends string[] | null>(
  data: readonly (string | SelectItem)[],
  message: string,
) => FormValuePredicate<T> = (data, message) => value =>
  data.every(item => {
    const itemValue = typeof item === 'string' ? item : item.value
    return Array.isArray(value) ? value.includes(itemValue) : false
  })
    ? undefined
    : message

export const isNumberFormat: <T extends string | number>(
  message: string,
) => FormValuePredicate<T> = message => value =>
  String(value) && !isNaN(Number(value)) ? undefined : message

export const isDateFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value =>
    value?.match(/^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/) ? undefined : message

export const isEmailFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value =>
    value?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
      ? undefined
      : message

export const isPhoneFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (phone(value).isValid ? undefined : message)

export const isAddressFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (value?.match(/[A-Za-z0-9]+/) ? undefined : message)

export const isBirthdayFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (date(value).isValidBirthday ? undefined : message)

export const isDateRelativeRange: <T extends string>(
  args: {
    min?: { value: number; unit: dayjs.ManipulateType }
    max?: { value: number; unit: dayjs.ManipulateType }
  },
  message: string,
) => FormValuePredicate<T> =
  ({ min, max }, message) =>
  value => {
    if (!value || !dayjs(value).isValid) {
      return message
    }

    const now = dayjs()
    if (min && now.add(min.value, min.unit).isAfter(value, 'day')) {
      return message
    }

    if (max && now.add(max.value, max.unit).isBefore(value, 'day')) {
      return message
    }
  }

export const isDigitCodeLength: <T extends string>(
  length: number,
  message: string,
) => FormValuePredicate<T> = (length, message) => value =>
  value?.match(`^\\d{${length}}$`) ? undefined : message

export const isMonthFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (value?.match(/^0?[1-9]$|^1[0,1,2]$/) ? undefined : message)

export const isYearFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (value?.match(/^2[0-9]{3}$/) ? undefined : message)

export const isCvvCodeFormat: <T extends string>(message: string) => FormValuePredicate<T> =
  message => value => (value?.match(/^\d{3,4}$/) ? undefined : message)

export const isCreditCardNumberFormat: <T extends string>(
  message: string,
) => FormValuePredicate<T> = message => value => (value?.match(/^\d{12,19}$/) ? undefined : message)

export const isLength: <T extends string | unknown[]>(
  args: { length: number; op: '<=' | '>=' },
  message: string,
) => FormValuePredicate<T> =
  ({ length, op }, message) =>
  value => {
    switch (op) {
      case '<=': {
        return (value?.length ?? 0) > length ? message : undefined
      }
      case '>=': {
        return (value?.length ?? 0) < length ? message : undefined
      }
    }
  }

export const isValue: <T extends string>(
  args: { value: T; op: '!=' },
  message: string,
) => FormValuePredicate<T> =
  ({ value, op }, message) =>
  compare => {
    switch (op) {
      case '!=':
        return value === compare ? message : undefined
    }
  }

export const isInRange: <T extends string | number>(
  args: { min?: number; max?: number },
  message: string,
) => FormValuePredicate<T> =
  ({ min, max }, message) =>
  value =>
    String(value) &&
    !isNaN(Number(value)) &&
    (!min || Number(value) >= min) &&
    (!max || Number(value) <= max)
      ? undefined
      : message
