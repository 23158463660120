import { useToggle } from '@mantine/hooks'
import React from 'react'
import { TextInput, TextInputProps } from '.'
import { EyeIcon, EyeOffIcon } from '..'
import { TertiaryButton } from '../buttons'

export type SecureInputProps = Omit<TextInputProps, 'rightSection' | 'type'>

export const SecureInput = ({ disabled = false, ...props }: SecureInputProps) => {
  const [type, toggleType] = useToggle(['password', 'text'])

  return (
    <TextInput
      {...props}
      type={disabled ? 'password' : type}
      disabled={disabled}
      rightSection={
        !disabled && (
          <TertiaryButton
            size='sm'
            onClick={() => toggleType()}
            leftIcon={type === 'text' ? <EyeIcon /> : <EyeOffIcon />}
          />
        )
      }
    />
  )
}
