import { AxiosInstance, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios'
import clone from 'lodash.clone'

export const REQUEST_START_TIME_HEADER_MS = 'request-start-time-ms'
export const LOCAL_TIMEZONE_HEADER = 'local-timezone'
const CONTENT_LENGTH_HEADER = 'content-length'

const getRequestDuration = (config: AxiosRequestConfig) => {
  if (config.headers?.[REQUEST_START_TIME_HEADER_MS]) {
    const currentTimeMs = new Date().getTime()
    const startTimeMs = parseInt(config.headers[REQUEST_START_TIME_HEADER_MS], 10)
    return currentTimeMs - startTimeMs
  }

  return -1
}

export const getApiUrl = (config: AxiosRequestConfig) => {
  return `${config.method?.toUpperCase()} ${config.url}`
}

export type FormattedAxiosRequestConfig = {
  headers: AxiosRequestConfig['headers']
  params: Record<string, string>
  data: string | undefined
  baseUrl: string | undefined
  url: string | undefined
  apiUrl: string
  method: string
}

export const getFormattedRequest = (req: AxiosRequestConfig): FormattedAxiosRequestConfig => {
  let data = undefined

  if (req.data) {
    try {
      data = JSON.stringify(req.data)
    } catch (err) {
      data = 'Unable to stringify request data'
    }
  }

  // Must make a copy before mutating any properties
  const headers: AxiosRequestConfig['headers'] = {
    ...req.headers,
  }

  if (headers.Authorization) {
    headers.Authorization = 'REDACTED'
  }

  return {
    data,
    headers,
    params: req.params,
    baseUrl: req.baseURL,
    url: req.url,
    apiUrl: getApiUrl(req),
    method: `${req.method?.toUpperCase()}`,
  }
}

export type FormattedAxiosResponse = {
  data: string | undefined
  status: number
  headers: AxiosResponse['headers']
  statusText: string
  requestDurationMs: number
}

const MAX_CONTENT_LENGTH_BYTES = 200000

export const getFormattedResponse = (res: AxiosResponse): FormattedAxiosResponse => {
  let data = undefined

  const contentLength = parseInt(res.headers?.[CONTENT_LENGTH_HEADER], 10) || 0

  if (contentLength > MAX_CONTENT_LENGTH_BYTES) {
    data = `Omitted because ${CONTENT_LENGTH_HEADER} ${contentLength} exceeds ${MAX_CONTENT_LENGTH_BYTES}`
  } else if (res.data) {
    try {
      data = JSON.stringify(res.data)
    } catch (err) {
      data = 'Unable to stringify response data'
    }
  }

  return {
    data,
    requestDurationMs: getRequestDuration(res.config),
    headers: res.headers,
    status: res.status,
    statusText: res.statusText,
  }
}

export type FormattedAxiosError = {
  errorMessage: string
  errorName: string
  errorSummary: string
  axiosErrorType?: string | undefined
  request?: FormattedAxiosRequestConfig | undefined
  response?: FormattedAxiosResponse | undefined
}

type OnRequestFulfilled = (request: FormattedAxiosRequestConfig) => void
type OnRequestRejected = (error: FormattedAxiosError) => void
type OnResponseFulfilled = (response: {
  response: FormattedAxiosResponse
  request: FormattedAxiosRequestConfig
}) => void
type OnResponseRejected = (error: FormattedAxiosError) => void

/**
 * Convenience hooks that work very similiarly to Axios interceptors,
 * except that they don't allow for modification of the request or response.
 * Good for use with read-only operations like logging.
 */
export type BaseApiHooks = {
  onRequestFulfilled?: OnRequestFulfilled
  onRequestRejected?: OnRequestRejected
  onResponseFulfilled?: OnResponseFulfilled
  onResponseRejected?: OnResponseRejected
  responseRejection?: { omit: 'request'[] }
  headers?: { [key: string]: string | (() => string | null) }
}

export const addAxiosInterceptors = (instance: AxiosInstance, hooks: BaseApiHooks) => {
  instance.interceptors.request.use(
    config => {
      // Add a header to the request with the current time in milliseconds
      config.headers[REQUEST_START_TIME_HEADER_MS] = new Date().getTime().toString()

      if (hooks.headers) {
        for (const [key, value] of Object.entries(hooks.headers)) {
          const computedValue = typeof value === 'function' ? value() : value
          // Only set the header if the value is truthy
          if (computedValue) {
            config.headers[key] = computedValue
          }
        }
      }

      hooks.onRequestFulfilled?.(getFormattedRequest(config))
      return config
    },
    error => {
      hooks.onRequestRejected?.(formatAxiosError(error))
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    res => {
      hooks.onResponseFulfilled?.({
        request: getFormattedRequest(res.config),
        response: getFormattedResponse(res),
      })
      return res
    },
    error => {
      hooks.onResponseRejected?.(formatAxiosError(error))
      if (hooks.responseRejection?.omit.includes('request')) {
        error = clone(error)
        error.request = 'REDACTED'
      }

      return Promise.reject(error)
    },
  )
}

export const formatAxiosError = (error: unknown): FormattedAxiosError => {
  if (!isAxiosError(error)) {
    return {
      errorSummary: 'This is a non-axios error',
      errorMessage: error instanceof Error ? error.message : 'n/a',
      errorName: error instanceof Error ? error.name : 'n/a',
    }
  }

  if (error.response) {
    return {
      axiosErrorType: 'RESPONSE',
      errorSummary:
        'The request was made and the server responded with a status code that falls out of the range of 2xx',
      errorMessage: error.message,
      errorName: error.name,
      request: error.config ? getFormattedRequest(error.config) : undefined,
      response: getFormattedResponse(error.response),
    }
  }

  if (error.request) {
    return {
      axiosErrorType: 'REQUEST',
      errorSummary: 'The request was made but no response was received',
      errorMessage: error.message,
      errorName: error.name,
      request: getFormattedRequest(error.request),
    }
  }

  return {
    axiosErrorType: 'SETUP',
    errorSummary: 'Something happened in setting up the request that triggered an Error',
    errorMessage: error.message,
    errorName: error.name,
    request: error.config ? getFormattedRequest(error.config) : undefined,
  }
}
