// eslint-disable-next-line no-restricted-imports
import {
  Badge as MantineBadge,
  BadgeProps as MantineBadgeProps,
  useMantineTheme,
} from '@mantine/core'
import React from 'react'
import { Colors, HexColor } from './theme/base'

export type PillVariant = {
  variant?: 'outline' | 'filled'
}

export type PillStatus = 'error' | 'warning' | 'success' | 'none'

export type PillProps = { status: PillStatus } & PillVariant &
  Omit<MantineBadgeProps, 'variant' | 'sx' | 'size'>

function getThemeStyles({
  status,
  colors,
  variant,
}: {
  status: PillStatus
  colors: Colors
  variant: PillVariant['variant']
}) {
  // 'none' is an option for a pill status, but doesn't actually exist on the themes
  const statusColors: [HexColor, HexColor] =
    status === 'none' ? [colors.text[1], colors.background[3]] : colors[status]

  if (variant === 'filled') {
    return {
      fontColor: colors.text[0],
      backgroundColor: statusColors[1],
      borderColor: statusColors[1],
    }
  }

  // Default to outline
  return {
    fontColor: statusColors[0],
    backgroundColor: 'unset',
    borderColor: statusColors[0],
  }
}

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({ status, variant = 'outline', ...props }, ref) => {
    const {
      fontSizes,
      other: { colors, sizes, fontFamilies },
    } = useMantineTheme()

    const { fontColor, backgroundColor, borderColor } = getThemeStyles({
      status,
      colors,
      variant,
    })

    return (
      <MantineBadge
        ref={ref}
        variant={variant}
        tt='none'
        h='fit-content'
        px={sizes.gap.md}
        py={0}
        fz={fontSizes.xs}
        ff={fontFamilies.bold}
        lh='normal'
        {...props}
        styles={{
          rightSection: { marginLeft: sizes.gap.sm, lineHeight: 0 },
          leftSection: { marginRight: sizes.gap.sm, lineHeight: 0 },
          root: {
            flex: 'none',
            border: 'none',
            color: fontColor,
            backgroundColor,
            boxShadow: `0 0 0 ${sizes.border.md} ${borderColor} inset`,
            fontFamily: fontFamilies.bold,
            width: 'fit-content',
            '& svg': { height: sizes.icon.sm, width: sizes.icon.sm },
          },
        }}
      />
    )
  },
)

Pill.displayName = 'Pill'
