// eslint-disable-next-line no-restricted-imports
import { MantineThemeOther, ThemeIcon, ThemeIconProps, useMantineTheme } from '@mantine/core'
import React, { ReactNode } from 'react'
import { Text } from './typography'

type CircleWithTextProps = {
  children: ReactNode
  fontSize?: 'xs' | 'md'
  variant?: 'default' | 'error'
} & Omit<ThemeIconProps, 'variant'>

function getThemeStyles({
  themeName,
  variant,
  colors,
}: {
  themeName: MantineThemeOther['themeName']
  variant: 'default' | 'error'
  colors: MantineThemeOther['colors']
}) {
  if (themeName === 'daybreak' || themeName === 'equinox') {
    return {
      iconSize: 20,
      color: variant === 'default' ? colors.actions[0] : colors.error[0],
      fontColor: variant === 'default' ? colors.text[0] : colors.text[3],
    }
  }

  return { iconSize: 24, color: 'inherit', fontColor: colors.text[0] }
}

export const CircleWithText = ({
  children,
  fontSize = 'xs',
  variant = 'default',
  ...props
}: CircleWithTextProps) => {
  const {
    other: { fontFamilies, themeName, colors },
  } = useMantineTheme()

  const { iconSize, color, fontColor } = getThemeStyles({ themeName, variant, colors })

  return (
    <ThemeIcon radius='lg' size={iconSize} color={color} {...props}>
      <Text ff={fontFamilies.bold} size={fontSize} color={fontColor}>
        {children}
      </Text>
    </ThemeIcon>
  )
}
