// eslint-disable-next-line no-restricted-imports
import { Group, Stack, createStyles } from '@mantine/core'
import React, { PropsWithChildren, ReactNode } from 'react'
import { MantineColor, colorToHex, getBorder } from '../inputs'

export const TimelineCard = ({
  styles,
  leftSide,
  rightSide,
  children,
}: PropsWithChildren<{
  leftSide: ReactNode
  rightSide?: ReactNode
  styles?: MantineColor<{
    backgroundColor?: string
    borderColor?: string
  }>
}>) => {
  const { classes } = useTimelineCardOutlineStyle(styles ?? {})

  return (
    <Stack
      spacing='sm'
      className={classes.card}
      sx={({ other: { sizes } }) => ({ padding: sizes.padding.lg })}
    >
      <Group position='apart' spacing='sm' noWrap>
        {leftSide}
        {rightSide}
      </Group>
      {children}
    </Stack>
  )
}

export const useTimelineCardOutlineStyle = createStyles(
  (
    { other: { colors, sizes }, radius },
    styles: MantineColor<{
      backgroundColor?: string | undefined
      borderColor?: string | undefined
    }>,
  ) => {
    const { backgroundColor, borderColor } = colorToHex(styles, colors) ?? {}
    // If no background render a border or render a border if specified
    const renderBoxShadow = !backgroundColor || borderColor
    // Use specified border color, otherwise default color
    const boxShadowColor = colorToHex(borderColor, colors) ?? colors.background[3]

    return {
      card: {
        borderRadius: radius.sm,
        backgroundColor: colorToHex(backgroundColor, colors) ?? 'unset',
        boxShadow: renderBoxShadow
          ? getBorder({ width: sizes.border.md, color: boxShadowColor })
          : 'unset',
      },
    }
  },
)
