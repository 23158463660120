import React from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  Indicator as MantineIndicator,
  IndicatorProps as MantineIndicatorProps,
  useMantineTheme,
} from '@mantine/core'
import { MantineColor, colorToHex } from './inputs'

export type IndicatorProps = Omit<MantineIndicatorProps, 'styles' | 'size' | 'color' | 'offset'> & {
  color?: MantineColor
  offset?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Indicator = ({ color, offset, ...props }: IndicatorProps) => {
  const {
    other: { sizes, fontFamilies, colors },
    fontSizes,
  } = useMantineTheme()

  return (
    <MantineIndicator
      styles={{
        common: {
          backgroundColor: colorToHex(color, colors) ?? colors.actions[0],
          boxSizing: 'border-box',
          paddingLeft: sizes.padding.sm,
          paddingRight: sizes.padding.sm,
          right: offset ? `calc(${sizes.padding[offset]} * -1)` : 0,
          width: 'auto',
          height: 'auto',
          minHeight: sizes.icon.md,
          minWidth: sizes.icon.md,
          fontSize: `calc(${fontSizes.md} / 1.5)`,
          fontFamily: fontFamilies.regular,
          lineHeight: 'normal',
          cursor: 'pointer',
        },
      }}
      {...props}
    />
  )
}
