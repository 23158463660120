// eslint-disable-next-line no-restricted-imports
import { Image as MantineImage, ImageProps as MantineImageProps } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import React from 'react'

export const Image = (props: MantineImageProps) => {
  const containerSize = useElementSize()

  return (
    <MantineImage
      radius='xs'
      ref={containerSize.ref}
      height={props.fit === 'scale-down' ? containerSize.height : 'auto'}
      {...props}
    />
  )
}
