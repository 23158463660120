export type SortOrder = 'ASC' | 'DESC'

export const sortBy = <T>({ key, order = 'ASC' }: { key?: keyof T; order?: SortOrder }) => {
  return (a: T, b: T) => {
    const aVal = key ? a[key] : a
    const bVal = key ? b[key] : b

    if (aVal < bVal) {
      return order === 'ASC' ? -1 : 1
    }

    if (aVal > bVal) {
      return order === 'ASC' ? 1 : -1
    }

    return 0
  }
}
