/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-restricted-imports
import { Switch as MantineSwitch, SwitchProps as MantineSwitchProps, px } from '@mantine/core'
import React from 'react'
import { getOutlineFocused, getOutlinePressed } from '../inputs'

export type SwitchProps = Omit<MantineSwitchProps, 'styles' | 'sx' | 'size'> & {
  size?: 'md' | 'sm'
}

export const Switch = ({ size = 'md', ...props }: SwitchProps) => {
  return (
    <MantineSwitch
      {...props}
      styles={({ other: { sizes, colors }, fontSizes }) => {
        // Size of the track
        const trackWidth = px(sizes.iconbutton[size]) * 2
        const trackHeight = px(sizes.iconbutton[size])
        // Size of the padding inside the track
        const trackPadding = px(sizes.iconbutton[size]) / 8
        // Size of the diameter of the thumb (circle inside the track)
        const thumbDiameter = px(sizes.iconbutton[size]) * (3 / 4)
        // Calculate how far the thumb should be offset when toggled on
        const thumbOffset = trackWidth - thumbDiameter - trackPadding

        return {
          body: { alignItems: 'center' },
          track: {
            backgroundColor: colors.background[3],
            border: 'none',
            minWidth: trackWidth,
            width: trackWidth,
            height: trackHeight,
            'input:focus + &': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
            'input:active + &': {
              outline: 'none',
              boxShadow: getOutlinePressed({ colors, sizes }),
            },
            'input:checked + &': { backgroundColor: colors.success[0] },
          },
          label: { color: colors.text[0], fontSize: fontSizes[size], paddingLeft: sizes.gap.md },
          thumb: {
            border: 'none',
            width: thumbDiameter,
            height: thumbDiameter,
            left: trackPadding,
            'input:checked + * > &': { left: thumbOffset },
            'input:disabled + * > &': { backgroundColor: colors.background[3] },
          },
        }
      }}
    />
  )
}
