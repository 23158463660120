const PASSWORD_MIN_LENGTH = 8

export const validatePassword = (password: string, type: 'pin' | 'password') => {
  if (type === 'pin') {
    if (!password) {
      return 'empty-pin'
    }

    if (password.length < 4) {
      return 'short-pin'
    }

    if (password.length > 4) {
      return 'long-pin'
    }

    // Only allow digits
    if (!/^\d+$/.test(password)) {
      return 'invalid-pin'
    }
  } else {
    if (!password) {
      return 'empty-password'
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      return 'short-password'
    }
  }

  return 'valid'
}
