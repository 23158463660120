import { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'

export type BaseApiConfig = { axiosInstance: AxiosInstance }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestConfig<D = any> = AxiosRequestConfig<D> & {
  method: Method
  url: string
}

export class BaseApi {
  axiosInstance: AxiosInstance

  constructor({ axiosInstance }: BaseApiConfig) {
    this.axiosInstance = axiosInstance
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request = async <T, D = any>(requestConfig: RequestConfig<D>): Promise<AxiosResponse<T, D>> =>
    this.axiosInstance(requestConfig)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json = async <T, D = any>(requestConfig: RequestConfig<D>): Promise<T> => {
    const res = await this.request<T, D>(requestConfig)
    return res?.data
  }
}
