import React, { useMemo } from 'react'
import { useElementSize } from '@mantine/hooks'

export type PDFViewerProps = {
  src: File | string
  w?: number
  h?: number
  fit?: boolean
}

export const PDFViewer = ({ src, w, h, fit }: PDFViewerProps) => {
  const containerSize = useElementSize()

  const defaultWidth = 480
  const width = w ?? (fit ? containerSize.width : defaultWidth)
  const height = h ?? (fit ? containerSize.height : 'auto')

  const url = useMemo(
    () => `${typeof src === 'string' ? src : URL.createObjectURL(src)}#toolbar=0&navpanes=0`,
    [src],
  )

  return <iframe style={{ border: 0 }} width={width} height={height} src={url} />
}
