import { AxiosInstance } from 'axios'
import {
  OpheliaClientRequestConfig,
  OpheliaMethod,
  OpheliaOperation,
  OpheliaRequestClientParams,
  OpheliaRequestResponse,
  OpheliaResponse,
  OpheliaSpec,
} from './utils'

class OpheliaBase<T extends OpheliaSpec> {
  _parseOperation = (operation: keyof T & string, urlParams?: OpheliaRequestClientParams) => {
    const [method, rawUrl] = operation.split(' ') as [OpheliaMethod, string]

    let url = rawUrl

    Object.entries(urlParams || {}).forEach(([param, value]) => {
      url = url.replace(`:${param}`, String(value))
    })

    return [method, url] as [OpheliaMethod, string]
  }
}

export class OpheliaClient<T extends OpheliaSpec> extends OpheliaBase<T> {
  _axiosInstance: AxiosInstance

  constructor(config: { axiosInstance: AxiosInstance }) {
    super()
    this._axiosInstance = config.axiosInstance
  }

  request = async <
    K extends keyof T extends OpheliaOperation ? keyof T : never,
    ReqRes extends OpheliaRequestResponse<OpheliaOperation> = T[K],
    Res extends OpheliaResponse = ReqRes['res'],
  >(
    operation: K,
    config: OpheliaClientRequestConfig<
      ReqRes['req']['params'],
      ReqRes['req']['data'],
      ReqRes['req']['query']
    > = {},
  ) => {
    const { params: urlParams, query: params, ...configRest } = config
    const [method, url] = this._parseOperation(operation, urlParams)

    return this._axiosInstance.request<Res>({
      ...configRest,
      params,
      method,
      url,
    })
  }

  getQuery = <
    K extends keyof T extends OpheliaOperation ? keyof T : never,
    ReqRes extends OpheliaRequestResponse<OpheliaOperation> = T[K],
  >(
    operation: K,
    config: OpheliaClientRequestConfig<
      ReqRes['req']['params'],
      ReqRes['req']['data'],
      ReqRes['req']['query']
    > = {},
  ) => {
    const queryKey = [operation, config]
    const queryFn = async () => (await this.request(operation, config)).data
    return [queryKey, queryFn] as const
  }

  getMutation =
    <
      K extends keyof T extends OpheliaOperation ? keyof T : never,
      ReqRes extends OpheliaRequestResponse<OpheliaOperation> = T[K],
    >(
      operation: K,
    ) =>
    async (
      config: OpheliaClientRequestConfig<
        ReqRes['req']['params'],
        ReqRes['req']['data'],
        ReqRes['req']['query']
      > = {},
    ) => {
      return (await this.request(operation, config)).data
    }
}
