// eslint-disable-next-line no-restricted-imports
import {
  Flex,
  Accordion as MantineAccordion,
  AccordionProps as MantineAccordionProps,
} from '@mantine/core'
import React, { ReactNode } from 'react'
import { MinusCircleIcon, PlusCircleIcon } from './icons'
import { getOutline } from './inputs'

export type AccordionProps<Multiple extends boolean = false> = Omit<
  MantineAccordionProps<Multiple>,
  'styles' | 'chevron'
> & {
  chevronActive?: ReactNode
  chevronInactive?: ReactNode
}

const Accordion = <Multiple extends boolean = false>({
  children,
  chevronPosition = 'left',
  chevronActive = <MinusCircleIcon styled />,
  chevronInactive = <PlusCircleIcon styled />,
  ...props
}: AccordionProps<Multiple>) => {
  return (
    <MantineAccordion
      {...(props as MantineAccordionProps<Multiple>)}
      disableChevronRotation
      styles={({ other: { sizes, colors }, radius }) => {
        const controlPadding = sizes.padding.lg

        return {
          label: { color: colors.text[0], padding: 'unset' },
          control: {
            paddingTop: controlPadding,
            paddingBottom: controlPadding,
            paddingLeft: controlPadding,
            paddingRight: controlPadding,
            outline: 'none',
            '&:hover': { backgroundColor: 'transparent' },
            '&:focus': {
              outline: 'none',
              '.mantine-Accordion-chevron': {
                boxShadow: getOutline({
                  color: colors.actions[0],
                  gap: sizes.outline.sm,
                  width: sizes.outline.sm,
                  backgroundColor: colors.background[0],
                }),
              },
            },
          },
          content: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
          item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            borderBottomWidth: 2,
            borderBottomColor: colors.background[1],
            '.mantine-Accordion-chevron-active': { display: 'none' },
            '&:last-child': { borderBottom: 'none', paddingBottom: 0 },
            '&:first-of-type': { paddingTop: 0 },
            '&[data-active]': {
              '.mantine-Accordion-chevron-inactive': { display: 'none' },
              '.mantine-Accordion-chevron-active': { display: 'flex' },
            },
          },
          chevron: {
            borderRadius: radius.sm,
            height: sizes.icon.md,
            width: sizes.icon.md,
            minWidth: 'unset',
          },
        }
      }}
      chevronPosition={chevronPosition}
      chevron={
        <>
          <Flex className='mantine-Accordion-chevron-inactive'>{chevronInactive}</Flex>
          <Flex className='mantine-Accordion-chevron-active'>{chevronActive}</Flex>
        </>
      }
      multiple={props.multiple || false}
    >
      {children}
    </MantineAccordion>
  )
}

Accordion.Item = MantineAccordion.Item
Accordion.Control = MantineAccordion.Control
Accordion.Panel = MantineAccordion.Panel

export { Accordion }
