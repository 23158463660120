export enum ActionItemType {
  Manual = 'manual',
  LockNote = 'lock_note',
  SendPrescription = 'send_prescription',
  ScheduleFollowUpAppointment = 'schedule_follow_up_appointment',
  SchedulePostInTakeCheckInAppointment = 'schedule_post_intake_check_in_appointment',
  // NOTE: Left as 'attest_note' instead of 'attest_visit_note' to avoid breaking anything -MB
  AttestVisitNote = 'attest_note',
  AttestCaseReviewNote = 'attest_case_review_note',
}

export enum ActionItemStatus {
  Open = 'open',
  Complete = 'complete',
}

export enum ActionItemAssignmentMethod {
  /** An employee has manually assigned an action item to another employee */
  Direct = 'direct',
  /** An action item was automatically assigned to an employee by the system */
  Indirect = 'indirect',
}

export type ActionItemGroupProps = {
  [key: string]: {
    types?: string[]
    emptyStateText: string
  }
}

export const ActionItemTypeGroups: ActionItemGroupProps = {
  /**
   *  TODO: removing this, because it looks like nothing is ever getting queued here, so it's always empty -- 2/7/2023
   * 'On call scripts': {
   *  types: [ActionItemType.Manual],
   *  emptyStateText: 'All on call scripts sent',
   * },
   */

  'Queued scripts': {
    types: [ActionItemType.SendPrescription],
    emptyStateText: 'All queued scripts sent',
  },
  'Lock notes': { types: [ActionItemType.LockNote], emptyStateText: 'All notes locked' },
  'Attest notes': {
    types: [ActionItemType.AttestVisitNote, ActionItemType.AttestCaseReviewNote],
    emptyStateText: 'All notes attested',
  },
  Schedule: {
    types: [
      ActionItemType.ScheduleFollowUpAppointment,
      ActionItemType.SchedulePostInTakeCheckInAppointment,
    ],
    emptyStateText: 'All appointments scheduled',
  },
}

export const ActionItemDueDateGroups: ActionItemGroupProps = {
  'Past due': { emptyStateText: 'No action items are past due' },
  Today: { emptyStateText: 'All action items due today are completed' },
  Tomorrow: { emptyStateText: 'All action items due tomorrow are completed' },
  Later: { emptyStateText: 'All future action items are completed' },
}

export const ActionItemSortByOptions: {
  [key: string]: { label: string; groups?: ActionItemGroupProps }
} = {
  type: { label: 'action type', groups: ActionItemTypeGroups },
  patient: { label: 'patient' },
  dueDate: { label: 'due date', groups: ActionItemDueDateGroups },
}
