export const PSYCH_DIAGNOSIS_CODES = [
  'F06.30',
  'F32.0',
  'F32.1',
  'F32.2',
  'F32.3',
  'F32.4',
  'F32.5',
  'F32.81',
  'F32.9',
  'F32.A',
  'F33.0',
  'F33.1',
  'F33.2',
  'F33.3',
  'F33.41',
  'F33.42',
  'F33.9',
  'F34.1',
  'F40.0',
  'F40.1',
  'F40.10',
  'F40.11',
  'F40.8',
  'F40.9',
  'F41.0',
  'F41.1',
  'F41.3',
  'F41.8',
  'F41.9',
  'F43.0',
  'F43.1',
  'F43.10',
  'F43.11',
  'F43.12',
  'F53.0',
  'N94.3',
  'O99.340',
  'O99.341',
  'O99.342',
  'O99.343',
  'O99.345',
  'R45.851',
  'Z91.5',
] as const

export type PsychDiagnosisCode = (typeof PSYCH_DIAGNOSIS_CODES)[number]

export const INVALID_CPT_CODE = 'F11.2'

export const SMOKING_CESSATION_DIAGNOSIS_CODES = [
  'F17.210',
  'F17.211',
  'F17.213',
  'F17.218',
  'F17.219',
  'F17.220',
  'F17.221',
  'F17.223',
  'F17.228',
  'F17.229',
  'F17.290',
  'F17.291',
  'F17.293',
  'F17.298',
  'F17.299',
] as const

export type SmokingCessationDiagosisCode = (typeof SMOKING_CESSATION_DIAGNOSIS_CODES)[number]

export const ICD10CodeTypes = ['behavioralHealth', 'phq9Related', 'gad7Related'] as const

export type ICD10CodeType = (typeof ICD10CodeTypes)[number]
