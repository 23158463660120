/* eslint-disable no-magic-numbers */

const baseUnits = ['ms', 'sec', 'min', 'hour', 'day'] as const

type BaseUnit = (typeof baseUnits)[number]

const semanticUnits = ['mins', 'minute', 'minutes', 'second', 'seconds', 'days', 'hours'] as const

const timeUnits = [...baseUnits, ...semanticUnits] as const

type TimeUnit = (typeof timeUnits)[number]

type TimeVal = number

const timeUnitMap = {
  ms: {
    ms: 1,
    sec: 1 / 1000,
    min: 1 / 60000,
    hour: 1 / 3600000,
    day: 1 / 86400000,
  },
  sec: {
    ms: 1000,
    sec: 1,
    min: 1 / 60,
    hour: 1 / 3600,
    day: 1 / 86400,
  },
  min: {
    ms: 60000,
    sec: 60,
    min: 1,
    hour: 1 / 60,
    day: 1 / 1440,
  },
  hour: {
    ms: 3600000,
    sec: 3600,
    min: 60,
    hour: 1,
    day: 1 / 24,
  },
  day: {
    ms: 86400000,
    sec: 86400,
    min: 1440,
    hour: 24,
    day: 1,
  },
} satisfies Record<BaseUnit, Record<BaseUnit, TimeVal>>

export type SemanticTime = `${TimeVal} ${TimeUnit}`

const timeUnitToBaseUnit = {
  ms: 'ms',
  sec: 'sec',
  second: 'sec',
  seconds: 'sec',
  min: 'min',
  mins: 'min',
  minute: 'min',
  minutes: 'min',
  hour: 'hour',
  hours: 'hour',
  day: 'day',
  days: 'day',
} satisfies Record<TimeUnit, BaseUnit>

export const toTime = (semanticTime: SemanticTime) => {
  const [val, unit] = semanticTime.split(' ') as [TimeVal, TimeUnit]

  if (val < 0) {
    throw Error('toTime cannot compute using negative numbers.')
  }

  const baseTimeUnit = timeUnitToBaseUnit[unit]

  const response = {
    val: () => {
      return Number(val)
    },
  } as { [method in TimeUnit]: () => number } & { val: () => number }

  return timeUnits.reduce((acc, timeUnit) => {
    acc[timeUnit] = () => {
      return timeUnitMap[baseTimeUnit][timeUnitToBaseUnit[timeUnit]] * val
    }
    return acc
  }, response)
}
