// eslint-disable-next-line no-restricted-imports
import { Radio as MantineRadio, RadioProps as MantineRadioProps, MantineSize } from '@mantine/core'
import React, { ReactNode } from 'react'
import { useInlineInputThemeStyles } from '../inline-inputs.helpers'

export type RadioProps = Omit<
  MantineRadioProps,
  'styles' | 'icon' | 'label' | 'description' | 'fz'
> & {
  iconless?: boolean
  label: ReactNode
  description?: ReactNode
  radius?: MantineSize
  fz?: MantineSize
}

export const Radio = ({
  disabled = false,
  radius,
  checked,
  value,
  fz = 'md',
  iconless,
  description,
  ...props
}: RadioProps) => {
  const { styles, inputStyle } = useInlineInputThemeStyles({
    scale: 1 / 4,
    iconless: Boolean(iconless),
    description: Boolean(description),
    iconRadius: 'lg',
    inputRadius: radius,
    fz,
  })

  return (
    <MantineRadio
      {...props}
      /*
       * Adds data-checked attribute to root in order to style labels,
       * this is already added by the RadioGroup but not by checked prop.
       */
      wrapperProps={checked ? { ['data-checked']: true } : {}}
      value={value}
      checked={checked}
      disabled={disabled}
      description={description}
      styles={{ radio: inputStyle, ...styles }}
    />
  )
}
