// eslint-disable-next-line no-restricted-imports
import { Group } from '@mantine/core'
import React, { ReactNode } from 'react'
import { SolidCircleIcon } from './icons'

export type ProgressDotsProps = {
  steps: number
  currentStep: number
}

export const ProgressDots = ({ steps, currentStep }: ProgressDotsProps) => {
  const children: ReactNode[] = []

  for (let i = 0; i < steps; i++) {
    children.push(
      <SolidCircleIcon
        key={i}
        color={({ background }) => (currentStep === i ? background[5] : background[3])}
      />,
    )
  }

  return <Group spacing='sm'>{children}</Group>
}
