import React, { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Card as MantineCard, CardProps as MantineCardProps } from '@mantine/core'
import { MantineColor, colorToHex, getBorder } from './inputs'

type CommonCardProps = Omit<MantineCardProps, 'styles' | 'shadow' | 'sx'>

type CardProps = CommonCardProps & {
  styles?: MantineColor<{
    backgroundColor?: string | undefined
    borderColor?: string | undefined
  }>
}

type CardButtonProps = CommonCardProps & { onClick: () => void }

export const Card = forwardRef<HTMLDivElement, CardProps>(({ padding, styles, ...props }, ref) => {
  return (
    <MantineCard
      ref={ref}
      padding={padding ?? 'sm'}
      sx={({ other: { colors, sizes }, radius, shadows }) => {
        const { backgroundColor = colors.background[0], borderColor } =
          colorToHex(styles, colors) ?? {}

        return {
          flex: 1,
          overflow: 'hidden',
          backgroundColor,
          borderRadius: radius.sm,
          boxShadow: borderColor
            ? getBorder({ width: sizes.border.md, color: borderColor })
            : shadows.xs,
        }
      }}
      {...props}
    />
  )
})

Card.displayName = 'Card'

export const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  ({ padding, ...props }, ref) => {
    return (
      <MantineCard
        ref={ref}
        component='button'
        type='button'
        padding={padding ?? 'sm'}
        sx={({ other: { colors }, radius, shadows }) => {
          return {
            flex: 1,
            overflow: 'hidden',
            border: 'unset',
            backgroundColor: colors.background[0],
            borderRadius: radius.sm,
            cursor: 'pointer',
            boxShadow: shadows.xs,
            ':focus': {
              backgroundColor: colors.background[1],
            },
          }
        }}
        {...props}
      />
    )
  },
)

CardButton.displayName = 'Card'
