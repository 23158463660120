import {
  DateInput as MantineDatePicker,
  DateInputProps as MantineDatePickerProps,
} from '@mantine/dates'
import dayjs from 'dayjs'
import React from 'react'
import { InputError } from '../InputWrapper'
import { CalendarIcon } from '../icons'
import { getAttributes, getOutlineFocused, useInputThemeStyles } from './inputs.helpers'

export type DatePickerProps = Omit<
  MantineDatePickerProps,
  | 'error'
  | 'styles'
  | 'sx'
  | 'rightSection'
  | 'rightSectionWidth'
  | 'rightSectionProps'
  | 'firstDayOfWeek'
  | 'renderDay'
  | 'hideOutsideDates'
  | 'allowFreeInput'
  | 'dateParser'
  | 'icon'
  | 'onChange'
  | 'value'
  | 'variant'
  | 'description'
> & {
  value?: string | undefined | null
  onChange?: (value: string) => void
} & InputError

export const DatePicker = ({
  error,
  warning,
  success,
  disabled = false,
  value,
  onChange,
  explanation,
  ...props
}: DatePickerProps) => {
  const {
    styles,
    inputStyles,
    iconColor,
    clearButtonStyle,
    hoverBackgroundColor,
    theme: {
      radius,
      fontSizes,
      other: { sizes, colors, fontFamilies },
    },
  } = useInputThemeStyles()

  const disabledDayColor = colors.text[2]
  const dayColor = colors.text[0]

  // Fixes empty string, should be null as it's a controlled component, null means an unset value
  value = value === '' ? null : value

  return (
    <MantineDatePicker
      {...props}
      withCellSpacing={false}
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={value ? dayjs(value).toDate() : (value as any)}
      onChange={date => onChange?.(date ? dayjs(date).toString() : '')}
      valueFormat='MM/DD/YYYY'
      hideOutsideDates
      error={error ?? success ?? warning ?? explanation}
      icon={<CalendarIcon />}
      firstDayOfWeek={0}
      weekdayFormat='ddd'
      disabled={disabled}
      popoverProps={{
        withinPortal: true,
        styles: {
          dropdown: {
            padding: sizes.gap.xl,
            backgroundColor: colors.background[0],
          },
        },
      }}
      styles={{
        calendarHeader: { marginBottom: sizes.padding.md, maxWidth: 'unset', gap: sizes.gap.md },
        calendarHeaderControl: {
          width: sizes.icon.xl,
          height: sizes.icon.xl,
          color: iconColor,
          '> svg': { width: sizes.icon.md, height: sizes.icon.md },
          ':focus': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
          ':hover': { backgroundColor: hoverBackgroundColor },
          ':active': {
            backgroundColor: colors.actions[0],
            transform: 'none',
            color: colors.background[0],
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes }),
          },
          ':disabled': {
            boxShadow: 'unset',
            color: disabledDayColor,
            backgroundColor: 'unset',
            opacity: 1,
          },
        },
        pickerControl: {
          color: dayColor,
          lineHeight: 'normal',
          height: sizes.icon.xl,
          fontSize: fontSizes.md,
          ':focus': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
          ':hover': { backgroundColor: hoverBackgroundColor },
          ':active': {
            backgroundColor: colors.actions[0],
            transform: 'none',
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes }),
          },
        },
        day: {
          color: dayColor,
          borderRadius: radius.xl,
          lineHeight: 'normal',
          fontSize: fontSizes.md,
          width: sizes.icon.xl,
          height: sizes.icon.xl,
          ':focus': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
          ':hover': { backgroundColor: hoverBackgroundColor },
          ':active': {
            backgroundColor: colors.actions[0],
            transform: 'none',
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes }),
          },
          '&[data-selected]': {
            fontFamily: fontFamilies.bold,
            backgroundColor: colors.actions[0],
            ':hover': { backgroundColor: colors.actions[1] },
          },
          '&[data-weekend]': { color: dayColor },
          '&[data-disabled]': { color: disabledDayColor },
        },
        weekday: {
          textTransform: 'uppercase',
          fontSize: fontSizes.xs,
          color: colors.text[1],
          lineHeight: 'normal',
        },
        // Title Four
        calendarHeaderLevel: {
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          fontFamily: fontFamilies.bold,
          textTransform: 'uppercase',
          letterSpacing: 1,
          height: sizes.icon.xl,
          '> & svg': {
            color: colors.text[1],
            width: sizes.icon.md,
            height: sizes.icon.md,
          },
          ':hover': { backgroundColor: hoverBackgroundColor },
          ':active': {
            backgroundColor: colors.actions[0],
            transform: 'none',
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes }),
          },
          ':focus': {
            outline: 'none',
            boxShadow: getOutlineFocused({ colors, sizes }),
          },
        },
        rightSection: {
          width: 'unset',
          marginRight: sizes.padding.md,
          '& svg': { width: sizes.icon.md, height: sizes.icon.md },
          // Modify the clear button
          '> button': clearButtonStyle,
        },
        input: inputStyles,
        ...styles,
      }}
    />
  )
}
