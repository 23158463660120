export type TruepillRequest = {
  patient_token: string
  patient_first_name?: string
  patient_last_name?: string
  otc_products: {
    quantity: number
    sku: string
  }[]
  address_to_name: string
  address_to_street1: string
  address_to_street2: string
  address_to_city: string
  address_to_state: string
  address_to_zip: string
  address_to_country: string
  address_to_phone: string
  metadata?: string
  shipping_method: string
  signature_confirmation?: boolean
  notes?: string
  patient_payment_type: string
  patient_survey: {
    medications: null
    allergies: null
    conditions: null
  }
}

export type TruepillResponse = {
  request_id: string
  timestamp: number
  status: string
  details: {
    message: string
    metadata: string
    date_filled?: string
    location?: string
    order_token?: string
    patient_token?: string
    tracking_url?: string
  }
}

export type TruepillErrorResponse = {
  statusCode: number
  error: string
  validation_errors: {
    key: string
    message: string
  }[]
}

type WebhookBase = {
  request_id: string
  timestamp: number
  callback_type: 'ORDER' | 'SHIPMENT'
  status: 'success' | 'error'
}

export type TruepillOrderWebhook = WebhookBase & {
  callback_type: 'ORDER'
  details: {
    metadata: string
    message: string
    date_filled: string
    order_token: string
    otc_products: {
      quantity: number
      sku: string
      bundle_item_partial_return_allowed: boolean
    }[]
    tracking_url: string
    location: string
  }
}

export type TruepillOrderErrorWebhook = WebhookBase & {
  callback_type: 'ORDER'
  details: {
    metadata: string
    error_code: string
    description: string
    message: string
    order_token: string
    otc_products: {
      sku: string
      requested_quantity: number
    }[]
  }
}

type TruepillShipmentWebhookStatus =
  | 'UNKNOWN'
  | 'PRE_TRANSIT'
  | 'TRANSIT'
  | 'DELIVERED'
  | 'RETURNED'
  | 'FAILED'

export type TruepillShipmentWebhook = WebhookBase & {
  callback_type: 'SHIPMENT'
  details: {
    metadata: string
    status: TruepillShipmentWebhookStatus
    message: string
    eta: string
    tracking_number: string
    tracking_url: string
  }
}

export type TruepillCreatePatientRequest = {
  first_name: string
  last_name: string
  /** YYYYMMDD */
  dob: string
  /** male or female */
  gender: string
}

export type TruepillCreatePatientResponse = {
  patient_token: string
}
