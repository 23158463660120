import { Day, StateAbbr } from '.'

export type OcpOverrideModel = {
  // state abbreviation
  state: StateAbbr
  // day of week as a string
  dayOfWeek: Day
  // calendar date to override, stored in firebase UTC timestamp. is always startOf day server time
  overrideDate: string
  employeeId: string
  // createdOn date stored in firebase UTC timestamp
  createdAt: string
  deleted: boolean
}

export type OcpRecurringModel = {
  // state abbreviation
  state: StateAbbr
  // follow existing convention for timezone used in appointment
  timezone: string
  // 30 or 60 but can be any integer -- we currently do not expose a way to update shift duration
  shiftDuration: number
  // The local time the shift starts M-F
  weekdayShiftStart: string
  weekdayShiftEnd: string
  // The local time the shift starts saturday and sunday
  weekendShiftStart: string
  weekendShiftEnd: string
  employeeIdByDay: {
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
    sunday: string
  }
  // when the series starts, date stored as firebase UTC timestamp
  seriesStartTime: string
  // when this series ended, date stored as firebase UTC timestamp or null if latest
  seriesEndTime: string | null
  // date stored as firebase UTC timestamp
  createdAt: string
  active: boolean
}
