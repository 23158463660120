function getStorageItem(key: string, options?: { useSessionStorage?: boolean }) {
  const storage = options?.useSessionStorage ? sessionStorage : localStorage
  const value = storage.getItem(key)

  try {
    if (value) {
      return JSON.parse(value)
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('Unable to parse storage item.')
  }

  // Some values might not be stringified yet
  return value
}

function setStorageItem(key: string, value: unknown, options?: { useSessionStorage?: boolean }) {
  const storage = options?.useSessionStorage ? sessionStorage : localStorage
  return storage.setItem(key, JSON.stringify(value))
}

function removeStorageItem(key: string, options?: { useSessionStorage?: boolean }) {
  const storage = options?.useSessionStorage ? sessionStorage : localStorage
  return storage.removeItem(key)
}

function clearStorage(useSessionStorage?: boolean) {
  const storage = useSessionStorage ? sessionStorage : localStorage
  return storage.clear()
}

export function storage<L, S>() {
  return {
    getLocalStorageItem<K extends keyof L = keyof L>(key: K): L[K] | null {
      return getStorageItem(key as string) as L[K]
    },
    getSessionStorageItem<K extends keyof S = keyof S>(key: K): S[K] | null {
      return getStorageItem(key as string, { useSessionStorage: true }) as S[K]
    },
    setLocalStorageItem<K extends keyof L = keyof L>(key: K, value: L[K]) {
      setStorageItem(key as string, value)
    },
    setSessionStorageItem<K extends keyof S = keyof S>(key: K, value: S[K]) {
      setStorageItem(key as string, value, { useSessionStorage: true })
    },
    removeLocalStorageItem<K extends keyof L = keyof L>(key: K) {
      removeStorageItem(key as string)
    },
    removeSessionStorageItem<K extends keyof S = keyof S>(key: K) {
      removeStorageItem(key as string, { useSessionStorage: true })
    },
    clearLocalStorage() {
      clearStorage()
    },
    clearSessionStorage() {
      clearStorage(true)
    },
  }
}
