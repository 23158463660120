type NameArg = {
  first?: string | null | undefined
  last?: string | null | undefined
}

export const name = (arg: NameArg) => {
  const [_first, _last] = [arg.first, arg.last].map(n => n?.trim())

  return {
    full() {
      return [_first, _last].filter(Boolean).join(' ')
    },
    lastCommaFirst() {
      return [_last, _first].filter(Boolean).join(', ')
    },
  }
}
