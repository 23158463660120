import { OpheliaRoute } from '..'

export type OphelinkApi = OpheliaRoute<
  'GET /:linkId',
  {
    req: {
      params: {
        linkId: string
      }
    }
    res: string
  }
>
