import { DecoratedProblem, Phq8FormResponse } from '.'
import {
  AsqFormResponse,
  Barc10FormResponse,
  CaseReviewNote,
  CocmTimeTrackingModel,
  DatabaseMetadata,
  Gad7FormResponse,
  Phq9FormResponse,
} from './models'
import { COCMRegistryItem } from './models/cocm'
import { PromptResponseContextKey } from './workflows'
import { AsqFormPromptResponseContextKey } from './workflows/contextKeys/asqForm'

export type ObjectiveMeasuresTitle = 'PHQ-9' | 'PHQ-8' | 'GAD-7' | 'BARC-10' | 'ASQ'

export type DecoratedCocmItem = COCMRegistryItem & {
  startedTreatmentDate: string | null
  behavioralHealthDiagnoses: DecoratedProblem[]
  firstName: string
  lastName: string
  employeeName: string
  decoratedPhqResponses: DecoratedObjectiveMeasureResponse<Phq9FormResponse>[] | null
  decoratedGad7Responses: DecoratedObjectiveMeasureResponse<Gad7FormResponse>[] | null
  decoratedBarc10Responses: DecoratedObjectiveMeasureResponse<Barc10FormResponse>[] | null
  mostRecentPhqResponse: Phq9FormResponse | null
  mostRecentGad7Response: Gad7FormResponse | null
  mostRecentBarc10Response: Barc10FormResponse | null
  firstPhqResponse: Phq9FormResponse | null
  firstGad7Response: Gad7FormResponse | null
  firstBarc10Response: Barc10FormResponse | null
  timeThisMonth: number
  suicidality?: boolean
}

export type DecoratedCaseReviewItem = COCMRegistryItem & {
  timeThisMonth: number
  firstName: string
  lastName: string
  assignedTnName: string
  lockedByName?: string
  mostRecentCaseReviewNote: CaseReviewNote | null
  daysSinceLastReview: number | null
}

export type DecoratedCocmTimeTracking = CocmTimeTrackingModel & {
  employeeName: string
} & Partial<DatabaseMetadata>

export type ObjectiveMeasureResponse =
  | Phq9FormResponse
  | Gad7FormResponse
  | Barc10FormResponse
  | AsqFormResponse

export type DecoratedObjectiveMeasureAdditionalProperties =
  | {
      completedBy: 'patient'
    }
  | {
      completedBy: 'clinician'
      employeeName: string
      employeeId: string
    }

export type DecoratedObjectiveMeasureResponse<
  T extends ObjectiveMeasureResponse = ObjectiveMeasureResponse,
> = T & DecoratedObjectiveMeasureAdditionalProperties

export type ObjectiveMeasure = ObjectiveMeasureResponse &
  DecoratedObjectiveMeasureAdditionalProperties

export type PatientCocmResponses = {
  phq8: Phq8FormResponse[]
  phq9: Phq9FormResponse[]
  gad7: Gad7FormResponse[]
  barc10: Barc10FormResponse[]
  asq: AsqFormResponse[]
}

export enum Phq9Prompts {
  little_interest,
  feeling_down,
  trouble_falling_asleep,
  feeling_tired,
  poor_appetite,
  feeling_bad_about_yourself,
  trouble_concentrating,
  moving_or_speaking_slowly,
  thoughts_that_you_would,
}

export enum Phq8Prompts {
  little_interest,
  feeling_down,
  trouble_falling_asleep,
  feeling_tired,
  poor_appetite,
  feeling_bad_about_yourself,
  trouble_concentrating,
  moving_or_speaking_slowly,
}

export enum PhqScores {
  not_at_all = 0,
  several = 1,
  more_than_half = 2,
  nearly_every = 3,
}

export const Phq9PromptStrings = [
  'Little interest or pleasure in doing things',
  'Feeling down, depressed, or hopeless',
  'Trouble falling or staying asleep, or sleeping too much',
  'Feeling tired or having little energy',
  'Poor appetite or overeating',
  'Feeling bad about yourself — or that you are a failure or have let yourself or your familiy down',
  'Trouble concentrating on things, such as reading the newspaper or watching television',
  'Moving or speaking so slowly that other people could have noticed. Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual',
  'Thoughts that you would be better off dead or of hurting yourself',
]

export const Phq8PromptStrings = [
  'Little interest or pleasure in doing things',
  'Feeling down, depressed, or hopeless',
  'Trouble falling or staying asleep, or sleeping too much',
  'Feeling tired or having little energy',
  'Poor appetite or overeating',
  'Feeling bad about yourself — or that you are a failure or have let yourself or your familiy down',
  'Trouble concentrating on things, such as reading the newspaper or watching television',
  'Moving or speaking so slowly that other people could have noticed. Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual',
]

export const PHQ_SUICIDALITY_RESPONSE_CONTEXT_KEYS = [
  'thoughts_that_you_would_not_at_all',
  'thoughts_that_you_would_several',
  'thoughts_that_you_would_more_than_half',
  'thoughts_that_you_would_nearly_every',
] as const

export const Phq9AndGad7ScoreMapping: { [key: number]: string } = {
  0: 'Not at all',
  1: 'Several days',
  2: 'More than half the days',
  3: 'Nearly every day',
}

export enum Gad7Prompts {
  feeling_nervous,
  not_being_able_to,
  worrying_too_much,
  trouble_relaxing,
  being_so_restless,
  becoming_easily_annoyed,
  feeling_afraid,
}

export enum Gad7Scores {
  not_at_all = 0,
  several = 1,
  more_than_half = 2,
  nearly_every = 3,
}

export const Gad7PromptStrings = [
  'Feeling nervous, anxious, or on edge',
  'Not being able to stop or control worrying',
  'Worrying too much about different things',
  'Trouble relaxing',
  'Being so restless that it is hard to sit still',
  'Becoming easily annoyed or irritable',
  'Feeling afraid, as if something awful might happen',
]

export enum Barc10Prompts {
  there_are_more_important_things,
  in_general_i_am_happy,
  i_have_enough_energy,
  i_am_proud_of_the_community,
  i_get_lots_of_support,
  i_regard_my_life_as_challenging,
  my_living_space_has_helped,
  i_take_full_responsibility,
  i_am_happy_dealing,
  i_am_making_good_progress,
}

export enum Barc10Scores {
  strongly_disagree = 1,
  disagree,
  slightly_disagree,
  slightly_agree,
  agree,
  strongly_agree,
}

export const Barc10ScoreMapping: { [key: number]: string } = {
  1: 'Strongly Disagree',
  2: 'Disagree',
  3: 'Somewhat Disagree',
  4: 'Somewhat Agree',
  5: 'Agree',
  6: 'Strongly Agree',
}

export const Barc10PromptStrings = [
  'There are more important things to me in life than using substances',
  'In general I am happy with my life',
  'I have enough energy to complete the tasks I set myself',
  'I am proud of the community I live in and feel part of it',
  'I get lots of support from friends',
  'I regard my life as challenging and fulfilling without the need for using drugs or alcohol',
  'My living space has helped to drive my recovery journey',
  'I take full responsibility for my actions',
  'I am happy dealing with a range of professional people',
  'I am making good progress on my recovery journey',
]

export const phq9ResponseContextKeyToScore = (
  contextKey: PromptResponseContextKey<'phq_9_form' | 'phq_8_form'>,
) => {
  if (contextKey.includes('_not_at_all')) {
    return PhqScores.not_at_all
  } else if (contextKey.includes('_several')) {
    return PhqScores.several
  } else if (contextKey.includes('_more_than_half')) {
    return PhqScores.more_than_half
  } else if (contextKey.includes('_nearly_every')) {
    return PhqScores.nearly_every
  }
}

export const gad7ResponseContextKeyToScore = (
  contextKey: PromptResponseContextKey<'gad_7_form'>,
) => {
  if (contextKey.includes('_not_at_all')) {
    return Gad7Scores.not_at_all
  } else if (contextKey.includes('_several')) {
    return Gad7Scores.several
  } else if (contextKey.includes('_more_than_half')) {
    return Gad7Scores.more_than_half
  } else if (contextKey.includes('_nearly_every')) {
    return Gad7Scores.nearly_every
  }
  return 0
}

export const barc10ResponseContextKeyToScore = (
  contextKey: PromptResponseContextKey<'barc_10_form'>,
) => {
  if (contextKey.includes('_disagree')) {
    if (contextKey.includes('_strongly_disagree')) {
      return Barc10Scores.strongly_disagree
    } else if (contextKey.includes('_slightly_disagree')) {
      return Barc10Scores.slightly_disagree
    }
    return Barc10Scores.disagree
  } else if (contextKey.includes('_agree')) {
    if (contextKey.includes('_strongly_agree')) {
      return Barc10Scores.strongly_agree
    } else if (contextKey.includes('_slightly_agree')) {
      return Barc10Scores.slightly_agree
    }
    return Barc10Scores.agree
  }
  return 0
}

export enum AsqPrompts {
  wished_you_were_dead,
  you_or_your_family_better_off,
  thoughts_about_killing_yourself,
  tried_to_kill_yourself,
  thoughts_of_killing_yourself_right_now,
}

export const AsqPromptStrings = [
  'Have you wished you were dead?',
  'Have you felt that you or your family would be better off if you were dead?',
  'Have you been having thoughts about killing yourself?',
  'Have you ever tried to kill yourself?',
  'Are you having thoughts of killing yourself right now?',
]

export enum AsqScores {
  no = 0,
  yes = 1,
}

export const AsqScoreMapping: { [key: number]: string } = {
  0: 'No',
  1: 'Yes',
}

export const asqResponseContextKeyToScore = (contextKey: PromptResponseContextKey<'asq_form'>) => {
  if (contextKey.endsWith('_no')) {
    return AsqScores.no
  } else if (contextKey.endsWith('_yes')) {
    return AsqScores.yes
  }
  return 0
}

export const asqResponseContextKeyToPrompt = (contextKey: AsqFormPromptResponseContextKey) => {
  // TODO: is there a better way to index AsqPromptStrings without if - else logic?
  if (contextKey.includes('wished_you_were_dead')) {
    return AsqPromptStrings[AsqPrompts.wished_you_were_dead]!
  } else if (contextKey.includes('you_or_your_family_better_off')) {
    return AsqPromptStrings[AsqPrompts.you_or_your_family_better_off]!
  } else if (contextKey.includes('thoughts_about_killing_yourself')) {
    return AsqPromptStrings[AsqPrompts.thoughts_about_killing_yourself]!
  } else if (contextKey.includes('tried_to_kill_yourself')) {
    return AsqPromptStrings[AsqPrompts.tried_to_kill_yourself]!
  } else if (contextKey.includes('thoughts_of_killing_yourself_right_now')) {
    return AsqPromptStrings[AsqPrompts.thoughts_of_killing_yourself_right_now]!
  }
  return ''
}
