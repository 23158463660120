// eslint-disable-next-line no-restricted-imports
import { AspectRatio, AspectRatioProps, Box, LoadingOverlay } from '@mantine/core'
import React from 'react'

type PhotoFrameProps = Omit<AspectRatioProps, 'ratio' | 'sx' | 'styles'> & {
  loading?: boolean
  ratio?: number
}

export const PhotoFrame = React.forwardRef<HTMLDivElement, PhotoFrameProps>(
  // eslint-disable-next-line no-magic-numbers
  ({ loading = false, ratio = 148 / 93, children, ...props }, ref) => {
    return (
      <Box
        {...props}
        sx={({ radius, other: { colors, sizes } }) => ({
          backgroundColor: colors.background[2],
          borderRadius: radius.sm,
          padding: sizes.padding.xs,
        })}
      >
        <AspectRatio ref={ref} ratio={ratio}>
          {children}
          <LoadingOverlay visible={loading} />
        </AspectRatio>
      </Box>
    )
  },
)

PhotoFrame.displayName = 'PhotoFrame'
