import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { PinInput as MantinePinInput, PinInputProps as MantinePinInputProps } from '@mantine/core'
import { InputError, InputWrapper } from '../InputWrapper'
import { getAttributes, useInputThemeStyles } from './inputs.helpers'

type PinInputProps = Omit<
  MantinePinInputProps,
  | 'error'
  | 'placeholder'
  | 'icon'
  | 'iconWidth'
  | 'rightSection'
  | 'rightSectionProps'
  | 'rightSectionWidth'
> &
  InputError & { label?: string }

export const PinInput = ({
  error,
  warning,
  success,
  explanation,
  disabled = false,
  label,
  type = 'number',
  ...props
}: PinInputProps) => {
  const {
    styles,
    inputStyles,
    theme: {
      other: { fontFamilies, sizes },
    },
  } = useInputThemeStyles()

  return (
    <InputWrapper
      label={label}
      error={error}
      warning={warning}
      success={success}
      explanation={explanation}
      disabled={disabled}
    >
      <MantinePinInput
        {...props}
        {...getAttributes({
          ['data-disabled']: Boolean(disabled),
          ['data-error']: Boolean(error),
          ['data-warning']: Boolean(warning),
          ['data-success']: Boolean(success),
        })}
        type={type}
        placeholder=''
        disabled={disabled}
        styles={{
          ...styles,
          root: {
            ...styles.root,
          },
          input: {
            ...inputStyles,
            lineHeight: 'inherit',
            width: `2.25rem`,
            paddingLeft: sizes.gap.sm,
            paddingRight: sizes.gap.sm,
            paddingTop: sizes.gap.sm,
            paddingBottom: sizes.gap.sm,
            fontSize: '1.5rem',
            fontFamily: fontFamilies.bold,
          },
        }}
      />
    </InputWrapper>
  )
}
