import { InsuranceData, OpheliaRoute, PasswordType } from '../api'
import { AppointmentTypeString } from '../appointments'
import { ConsentModel, PatientModel } from '../models'
import { StateName } from '../states'

type AutomationPatientActionType =
  | 'delete-account'
  | 'create-account'
  | 'update-account'
  | 'create-consent'
  | 'update-insurance'
  | 'delete-no-phone-account'

type AutomationAppointmentActionType =
  | 'create-acuity-appointment'
  | 'create-appointment'
  | 'cancel-acuity-appointment'
  | 'delete-appointment'

export type AutomationPatientAction<
  A extends AutomationPatientActionType = AutomationPatientActionType,
> = A extends 'create-account'
  ? { action: A; email: string; passwordType: PasswordType; password: string; state: StateName }
  : A extends 'update-account'
  ? { action: A; patient: Partial<PatientModel> }
  : A extends 'create-consent'
  ? { action: A; consent: ConsentModel }
  : A extends 'update-insurance'
  ? { action: A; type: 'primary' | 'secondary'; insuranceData: Partial<InsuranceData> }
  : A extends 'delete-no-phone-account'
  ? { action: A; workflowSessionId: string }
  : { action: A }

export type AutomationAppointmentAction<
  A extends AutomationAppointmentActionType = AutomationAppointmentActionType,
> = A extends 'create-acuity-appointment'
  ? { action: A; visitTypeId: number; dateRange: [string, string] }
  : A extends 'create-appointment'
  ? { action: A; visitTypeString: AppointmentTypeString; datetime: string; durationMinutes: number }
  : A extends 'cancel-acuity-appointment' | 'cancel-appointment'
  ? { action: A; id: string; noShow?: boolean }
  : { action: A; id: string }

type AutomationEmployeeActionType = 'get-login-token'

export type AutomationEmployeeAction<
  A extends AutomationEmployeeActionType = AutomationEmployeeActionType,
> = { action: A }

export type AutomationApi = OpheliaRoute<
  'POST /patient',
  {
    req: { data: { phoneNumber: string; actions: AutomationPatientAction[] } }
    res: never
  }
> &
  OpheliaRoute<
    'POST /appointments',
    {
      req: { data: { phoneNumber: string; actions: AutomationAppointmentAction[] } }
      res: { id: string }[]
    }
  > &
  OpheliaRoute<'GET /patient/reserve', { req: never; res: { phoneNumber: string } }> &
  OpheliaRoute<
    'POST /employee',
    {
      req: { data: { oid: string; actions: AutomationEmployeeAction[] } }
      res: { token?: string }
    }
  >
