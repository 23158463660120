import { FormFieldType, FormFieldValue } from './formFieldType'

export type FormValue<F extends FormFieldType = FormFieldType> = Record<string, FormFieldValue<F>>

export type FormFieldSchema<
  P extends FormValue<F>,
  F extends FormFieldType,
> = FormFieldSchema.Basis<P> & FormFieldSchema.Additional<F>

export namespace FormFieldSchema {
  export type NoType<P extends FormValue<F>, F extends FormFieldType> = Basis<P> &
    Omit<Additional<F>, 'type'>

  export type Basis<P extends FormValue> = {
    field: Extract<keyof P, string>
    collapsible?: Condition<P>
    required?: boolean
    disabled?: boolean
  }

  export type Operation = '==' | '!=' | 'regexp'

  export type Condition<P extends FormValue = FormValue> = {
    field: Extract<keyof P, string>
    value: string | boolean | string[]
    operation: Operation
  }[]

  export type Message = MultiMessage<FormFieldType.Multi> | SingleMessage

  type SingleMessage =
    | {
        type: 'warning' | 'success' | 'error'
        message: string
      }
    | {
        type: 'warning' | 'success' | 'error'
        message: string
        value: string | boolean | string[]
        operation: Operation
      }

  type MultiMessage<F extends FormFieldType.Multi> = SingleMessage & {
    field: keyof FormFieldValue<F>
  }

  export type Additional<F extends FormFieldType = FormFieldType> = F extends 'photo'
    ? {
        type: F
        footer?: string
        label: string
        messages?: SingleMessage[]
      }
    : F extends 'select'
    ? {
        type: F
        data: {
          value: string
          label: string
        }[]
        label: string
        placeholder: string
        messages?: SingleMessage[]
      }
    : F extends 'address'
    ? {
        type: F
        label: string
        messages?: MultiMessage<F>[]
      }
    : F extends 'date'
    ? {
        type: F
        label: string
        context: 'birthday' | 'none'
        messages?: SingleMessage[]
      }
    : F extends 'phone-number' | 'checkbox'
    ? {
        type: F
        label: string
        messages?: SingleMessage[]
      }
    : F extends 'radio-group'
    ? {
        type: F
        data: {
          value: boolean
          label: string
        }[]
        label: string
        messages?: SingleMessage[]
      }
    : {
        type: F
        label: string
        placeholder?: string
        replacement?: RegExp | string
        maxLength?: number
        messages?: SingleMessage[]
      }
}
