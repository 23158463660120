// eslint-disable-next-line no-restricted-imports
import { Skeleton as MantineSkeleton, SkeletonProps as MantineSkeletonProps } from '@mantine/core'
import React from 'react'

export type SkeletonProps = MantineSkeletonProps

// This is to get around some issues with Mantine's skeleton component, mainly how it messes with z-indexes on child components
export const Skeleton = (props: SkeletonProps) => {
  const { children, ...rest } = props
  if (children) {
    // Mantine's default props for the skeleton component has visible set to true, so if it's not explicitly set to false, we'll render the skeleton
    if (props.visible !== false) {
      return <MantineSkeleton {...rest}>{children}</MantineSkeleton>
    }
    return <>{children}</>
  }
  return <MantineSkeleton {...props} />
}
