import { useLifecycle } from './hooks'

export const NavigateExternal = (props: { url: string; with: 'replace' | 'open' | 'assign' }) => {
  // Replaces the current tab
  let onMount = () => window.location.replace(props.url)

  // Opens a new tab
  if (props.with === 'open') {
    onMount = () => window.open(props.url, '_blank')
  }

  // Assigns the url to the current window
  if (props.with === 'assign') {
    onMount = () => window.location.assign(props.url)
  }

  useLifecycle({
    onMount,
  })

  return null
}
