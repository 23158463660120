// eslint-disable-next-line no-restricted-imports
import {
  Avatar as MantineAvatar,
  AvatarProps as MantineAvatarProps,
  useMantineTheme,
} from '@mantine/core'
import React from 'react'
import { UserIcon } from './icons'

type AvatarProps = Omit<MantineAvatarProps, 'radius' | 'color' | 'sx' | 'styles'> & {
  iconColor?: string
  backgroundColor?: string
}

export const Avatar = ({ children, src, ...rest }: AvatarProps) => {
  const {
    other: { sizes, colors },
  } = useMantineTheme()

  const { iconColor, backgroundColor, size } = rest

  return (
    <MantineAvatar
      {...rest}
      radius='xl'
      size={size || sizes.icon.xl}
      styles={{
        root: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        placeholder: {
          backgroundColor: backgroundColor || colors.actions[0],
          color: iconColor || 'default',
        },
      }}
      src={src as string}
    >
      {children ? children : <UserIcon size='md' color={colors => colors.background[0]} />}
    </MantineAvatar>
  )
}
