// eslint-disable-next-line no-restricted-imports
import {
  TimeInput as MantineTimeInput,
  TimeInputProps as MantineTimeInputProps,
} from '@mantine/dates'
import React from 'react'
import { InputError } from '../InputWrapper'
import { Formatter, getAttributes, useFormatter, useInputThemeStyles } from './inputs.helpers'

export type TimeInputProps = Omit<MantineTimeInputProps, 'withSeconds'> &
  InputError & {
    formatter?: Formatter
    onChange?: (value: string) => void
  }

export const TimeInput = ({
  error,
  warning,
  success,
  disabled = false,
  onChange,
  formatter,
  value,
  explanation,
  ...props
}: TimeInputProps) => {
  const { formatValue, onChangeFormat } = useFormatter({ formatter, onChange })

  const { styles, inputStyles } = useInputThemeStyles()

  return (
    <MantineTimeInput
      {...props}
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      error={error ?? success ?? warning ?? explanation}
      onChange={onChangeFormat}
      disabled={disabled}
      value={formatValue(value)}
      withSeconds={false}
      styles={{
        input: {
          ...inputStyles,
        },
        ...styles,
      }}
    />
  )
}
