import { Availability } from '../account'
import { InsurancePlanId } from '../insurance'

export namespace FormFieldType {
  export type Multi = 'address'

  export type Single =
    | 'date'
    | 'string'
    | 'select'
    | 'photo'
    | 'phone-number'
    | 'e-mail'
    | 'checkbox'
    | 'checkbox-group'
    | 'availabilities'
    | 'radio-group'
    | 'insurance-plan'
}

export type FormFieldType = FormFieldType.Single | FormFieldType.Multi

export type FormFieldValue<
  F extends FormFieldType = FormFieldType,
  S extends string = string,
> = F extends 'address'
  ? {
      address: string
      addressTwo: string
      city: string
      state: string
      zipCode: string
    }
  : F extends 'checkbox'
  ? boolean
  : F extends 'checkbox-group'
  ? S[]
  : F extends 'availabilities'
  ? Availability[]
  : F extends 'insurance-plan'
  ? InsurancePlanId
  : S
