import { isAxiosError } from 'axios'
import { formatAxiosError } from './formatAxiosError'

type SimpleLogger = Pick<typeof console, 'info' | 'error'>

export type LogSettledResultsOptions = {
  logger?: SimpleLogger
  logSummary?: boolean
  logBatchId?: boolean
  prefix?: string
}

const RADIX = 36
const SLICE_START = -6
// Example weak id: 'fol7o4'
const getWeakId = () => Math.random().toString(RADIX).slice(SLICE_START)

export const getSettledValue = <T>(result: PromiseSettledResult<T>): T | undefined => {
  if (result.status === 'fulfilled') {
    return result.value
  }

  return undefined
}

export const isFulfilled = <T>(
  result: PromiseSettledResult<T>,
): result is PromiseFulfilledResult<T> => {
  return result.status === 'fulfilled'
}

export const getFulfilledValue = <T>(result: PromiseFulfilledResult<T>): T => {
  return result.value
}

export const isRejected = <T>(result: PromiseSettledResult<T>): result is PromiseRejectedResult => {
  return result.status === 'rejected'
}

export const getRejectedReason = <T>(result: PromiseRejectedResult): T => {
  return result.reason
}

export const logSettledResults = (
  // Use `any` bc the logger does not care about the types of successfully resolved promises.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settledResults: PromiseSettledResult<any>[],
  options?: LogSettledResultsOptions,
) => {
  const { logger = console, prefix = '', logSummary = false, logBatchId = false } = options || {}
  const batchId = { batchId: getWeakId() }

  settledResults.forEach(result => {
    if (result.status === 'rejected') {
      const args = []

      if (logBatchId) {
        args.push(batchId)
      }

      if (prefix) {
        args.push(prefix)
      }

      if (isAxiosError(result.reason)) {
        args.push(formatAxiosError(result.reason))
      } else {
        args.push(result.reason)
      }

      logger.error(...args)
    }
  })

  if (logSummary) {
    const args = []

    const fulfilled = settledResults.filter(isFulfilled).length
    const rejected = settledResults.filter(isRejected).length
    const total = settledResults.length
    const summary = { total, fulfilled, rejected }

    if (logBatchId) {
      args.push(batchId)
    }

    args.push(summary)

    logger.info(...args)
  }
}
