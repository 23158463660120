import React from 'react'
import { Box, BoxProps, MediaQuery } from '.'

export const DesktopView = (props: { children: JSX.Element }) => {
  return (
    <MediaQuery className='desktop-view' smallerThan='sm' styles={{ display: 'none' }}>
      {props.children}
    </MediaQuery>
  )
}

export const MobileView = (props: { children: JSX.Element }) => {
  return (
    <MediaQuery className='mobile-view' largerThan='sm' styles={{ display: 'none' }}>
      {props.children}
    </MediaQuery>
  )
}

export const ResponsiveBox = ({
  mobile,
  desktop,
  ...boxProps
}: { mobile: JSX.Element; desktop: JSX.Element } & BoxProps) => {
  return (
    <Box component='div' {...boxProps}>
      <MobileView>{mobile}</MobileView>
      <DesktopView>{desktop}</DesktopView>
    </Box>
  )
}
