import { useEffect } from 'react'

export const useLifecycle = ({
  onMount,
  onUnmount,
}: {
  onMount?: (() => void) | null | undefined
  onUnmount?: (() => void) | null | undefined
}) => {
  useEffect(() => {
    onMount?.()
    return onUnmount ?? (() => void 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
