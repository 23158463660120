export const formularyMedicationNDCs = [
  // 'Buprenorphine (sublingual - tablet) 2mg',
  '00054017613',
  // 'Buprenorphine (sublingual - tablet) 8mg',
  '00054017713',
  // 'Buprenorphine-Naloxone (sublingual - film) 2mg-0.5mg',
  '00378876593',
  // 'Buprenorphine-Naloxone (sublingual - film) 4mg-1mg',
  '00378876693',
  // 'Buprenorphine-Naloxone (sublingual - film) 8mg-2mg',
  '00378876793',
  // 'Buprenorphine-Naloxone (sublingual - film) 12mg-3mg',
  '00378876893',
  // 'Buprenorphine-Naloxone (sublingual - tablet) 2mg-0.5mg',
  '00054018813',
  // 'Buprenorphine-Naloxone (sublingual - tablet) 8mg-2mg',
  '00054018913',
  // 'Suboxone (sublingual - film) 2mg-0.5mg',
  '12496120203',
  // 'Suboxone (sublingual - film) 4mg-1mg',
  '12496120403',
  // 'Suboxone (sublingual - film) 8mg-2mg',
  '12496120803',
  // 'Suboxone (sublingual - film) 12mg-3mg',
  '12496121203',
  // 'Zubsolv (sublingual - tablet) 0.7mg-0.18mg',
  '54123090730',
  // 'Zubsolv (sublingual - tablet) 1.4mg-0.36mg',
  '54123091430',
  // 'Zubsolv (sublingual - tablet) 2.9mg-0.71mg',
  '54123092930',
  // 'Zubsolv (sublingual - tablet) 5.7mg-1.4mg',
  '54123095730',
  // 'Zubsolv (sublingual - tablet) 8.6mg-2.1mg',
  '54123098630',
  // 'Zubsolv (sublingual - tablet) 11.4mg-2.9mg',
  '54123011430',
  // 'buPROPion (oral - tablet, extended release) 100mg/12hours',
  '00185041001',
  // 'buPROPion (oral - tablet, extended release) 200mg/12hours',
  '00185111160',
  // 'buPROPion (oral - tablet, extended release) 150mg/24hours',
  '00527241532',
  // 'buPROPion (oral - tablet, extended release) 300mg/24hours',
  '00527243032',
  // 'buPROPion (oral - tablet, extended release) 450mg/24hours',
  '47781063730',
  // 'buPROPion (oral - tablet, extended release) 150mg/12hours',
  '77771017560',
  // 'Mirtazapine (oral - tablet) 7.5mg',
  '13107000105',
  // 'Mirtazapine (oral - tablet) 15mg',
  '00378351501',
  // 'Mirtazapine (oral - tablet) 30mg',
  '00378353001',
  // 'Mirtazapine (oral - tablet) 45mg',
  '00378354501',
] as const
export type FormularyMedicationNDC = (typeof formularyMedicationNDCs)[number]

export type PrescriptionCoverageData = {
  formularyStatus: string | null
  priorAuthorizationRequired: boolean | null
  // lastUpdated denotes the last time the RxBenefits cron job was run for this medication
  lastUpdatedAt: string
}

export type PrescriptionBenefitsData = {
  // This data is collected from DS endpoint 3.11.2 Get coverage information
  prescriptionCoverageData: {
    medications: {
      [key in FormularyMedicationNDC]?: PrescriptionCoverageData
    }
  }
}
