// eslint-disable-next-line no-restricted-imports
import { Text as MantineText, TextProps as MantineTextProps, useMantineTheme } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import React, { HTMLAttributeAnchorTarget, useMemo } from 'react'
import { MantineColor, colorToHex } from '../inputs'

export type TextProps = Omit<MantineTextProps, 'sx' | 'color' | 'size' | 'variant' | 'style'> & {
  color?: MantineColor
  size?: 'md' | 'xs'
  bold?: boolean
}

export type LinkTextProps = Omit<TextProps, 'color'>

export function Text<C = 'div'>({
  bold = false,
  color,
  size = 'md',
  ...rest
}: PolymorphicComponentProps<C, TextProps>) {
  const {
    other: { colors, fontFamilies },
  } = useMantineTheme()

  return (
    <MantineText<C>
      ff={bold ? fontFamilies.bold : fontFamilies.regular}
      lh='normal'
      color={colorToHex(color, colors) ?? colors.text[0]}
      size={size}
      // Cast as any to avoid type error due to undefined props
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
    />
  )
}

export function LinkText<C = 'div'>({
  span = false,
  ...rest
}: PolymorphicComponentProps<C, LinkTextProps> & { span?: boolean }) {
  const {
    other: { fontFamilies, colors },
  } = useMantineTheme()

  return (
    <MantineText<C>
      ff={fontFamilies.bold}
      lh='normal'
      // Cast as any to avoid type error due to undefined props
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
      sx={{
        color: colors.actions[1],
        whiteSpace: 'pre',
        ':hover': { cursor: 'pointer', textDecoration: 'underline' },
        ':focus': { outline: 'none', textDecoration: 'underline' },
      }}
      span={span}
    />
  )
}

export const ExternalLinkText = ({
  href,
  target,
  component = 'a',
  span = false,
  ...rest
}: PolymorphicComponentProps<'a', LinkTextProps> & { span?: boolean }) => {
  const autoTarget = useMemo<HTMLAttributeAnchorTarget>(
    () =>
      ['tel:', 'sms:', 'mailto:', 'geo:'].some(tag => href?.includes(tag)) ? '_self' : '_blank',
    [href],
  )

  return (
    <LinkText
      href={href}
      component={component}
      target={target ?? autoTarget}
      rel='noopener noreferrer'
      span={span}
      {...rest}
    />
  )
}
