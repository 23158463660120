export const CENTS_PER_DOLLAR = 100

/*
 * Takes an amount and returns a string formatted as a dollar amount.
 * This is useful for formatting fraction digits, as well as
 * adding a dollar sign (if needed).
 */
export const formatDollarAmount = ({
  amount,
  unit = 'dollars',
  style = 'currency',
}: {
  amount: number
  unit?: 'dollars' | 'cents'
  style?: 'currency' | 'decimal'
}) => {
  const amountInDollars = unit === 'dollars' ? amount : amount / CENTS_PER_DOLLAR

  return new Intl.NumberFormat('en-US', {
    style,
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(amountInDollars) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(amountInDollars) ? 0 : 2,
  }).format(amountInDollars)
}
