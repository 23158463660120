// Adapted from https://ui.mantine.dev/category/tables
import React from 'react'
import { useMantineTheme } from '.'

export const Td = (props: React.ComponentPropsWithoutRef<'td'>) => {
  const {
    other: {
      sizes: { padding },
    },
  } = useMantineTheme()

  return (
    <td
      className='mantine'
      style={{
        paddingLeft: `calc(${padding.xs} * 3)`,
        paddingRight: `calc(${padding.xs} * 3)`,
      }}
    >
      {props.children}
    </td>
  )
}
