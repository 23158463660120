import React, { SVGProps } from 'react'

export const SolidCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      {...props}
    >
      <circle cx='12' cy='12' r='11'></circle>
    </svg>
  )
}
