// eslint-disable-next-line no-restricted-imports
import { CSSObject, MantineThemeOther, useMantineTheme } from '@mantine/core'
import { useMemo } from 'react'

export const getBorder = ({ width, color }: { width: string; color: string }) =>
  `inset 0 0 0 ${width} ${color}`

// Note: use `boxShadow` rather than `outline` due to browser discrepencies around `outline`
export const getOutline = ({
  gap,
  width,
  color,
  backgroundColor,
}: {
  color: string
  gap: string
  width: string
  backgroundColor: string
}) => `0 0 0 ${gap} ${backgroundColor}, 0 0 0 calc(${gap} + ${width}) ${color}`

export const getOutlineFocused = ({
  colors,
  sizes: { outline },
  backgroundColor,
  color,
}: {
  colors: MantineThemeOther['colors']
  sizes: MantineThemeOther['sizes']
  backgroundColor?: MantineColor | undefined
  color?: MantineColor | undefined
}) =>
  getOutline({
    backgroundColor: colorToHex(backgroundColor, colors) ?? colors.background[0],
    color: colorToHex(color, colors) ?? colors.actions[0],
    gap: outline.md,
    width: outline.lg,
  })

export type MantineColor<T extends string | Record<string, string | undefined> = string> =
  | T
  | ((colors: MantineThemeOther['colors']) => T | undefined)

export function colorToHex<T extends string | Record<string, string | undefined>>(
  color: MantineColor<T> | undefined,
  colors: MantineThemeOther['colors'],
) {
  return typeof color === 'function' ? color?.(colors) : color
}

export const getOutlinePressed = ({
  colors,
  sizes: { outline },
  backgroundColor,
}: {
  colors: MantineThemeOther['colors']
  sizes: MantineThemeOther['sizes']
  backgroundColor?: MantineColor | undefined
}) =>
  getOutline({
    backgroundColor: colorToHex(backgroundColor, colors) ?? colors.background[0],
    color: colors.background[1],
    gap: outline.md,
    width: outline.lg,
  })

export type Formatter = RegExp | string | ((value: string) => string)

export const useFormatter = ({
  formatter,
  onChange,
}: {
  formatter: Formatter | undefined
  onChange: ((value: string) => void) | undefined
}) => {
  const formatValue = (
    value: string | undefined | readonly string[] | number,
  ): string | undefined => {
    if (value === undefined) {
      return value
    }

    if (typeof formatter === 'function') {
      return formatter(String(value))
    } else if (formatter) {
      return String(value).replace(formatter, '')
    }

    return String(value)
  }

  const onChangeFormat: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    if (onChange) {
      const { value } = event.target
      onChange(formatValue(value) ?? '')
    }
  }

  return { formatValue, onChangeFormat }
}

export const getAttributes = (props: Record<string, boolean>) => {
  for (const key of Object.keys(props)) {
    if (!props[key]) {
      delete props[key]
    }
  }

  return props
}

export const useInputThemeStyles = () => {
  const theme = useMantineTheme()

  return useMemo(() => {
    const {
      radius,
      fontSizes,
      other: { colors, themeName, sizes, fontFamilies },
    } = theme

    const fontColor = colors.text[0]
    const iconColor = colors.actions[0]
    const disabledColor = colors.actions[3]
    const placeholderFontColor = colors.actions[3]
    let selectedFontColor: string
    let hoverIconColor: string
    let hoverIconBackgroundColor: string
    let hoverBackgroundColor: string

    if (themeName === 'daybreak' || themeName === 'equinox') {
      selectedFontColor = colors.text[0]
      hoverIconColor = colors.text[0]
      hoverIconBackgroundColor = colors.background[2]
      hoverBackgroundColor = colors.background[2]
    } else {
      // Default to twilight
      selectedFontColor = colors.text[3]
      hoverIconColor = colors.actions[1]
      hoverIconBackgroundColor = 'unset'
      hoverBackgroundColor = colors.background[1]
    }

    return {
      selectedFontColor,
      fontColor,
      hoverBackgroundColor,
      iconColor,
      theme,
      styles: {
        root: {
          '&[disabled],&[data-disabled]': {
            '.mantine-InputWrapper-label': { color: disabledColor },
            '.mantine-Input-icon': { color: disabledColor },
            '.mantine-Input-rightSection': { color: disabledColor },
            '.mantine-InputWrapper-error': { color: disabledColor },
            '.mantine-Input-wrapper': {
              backgroundColor: colors.background[1],
              boxShadow: `0 0 0 ${sizes.border.md} ${colors.actions[2]} inset`,
            },
          },
          '&[data-warning]': {
            '.mantine-Input-wrapper': {
              boxShadow: `0 0 0 ${sizes.border.md} ${colors.warning[0]} inset`,
            },
            '.mantine-InputWrapper-error': {
              color: colors.warning[0],
            },
            '.mantine-Input-rightSection': { borderColor: colors.warning[0] },
          },
          '&[data-success]': {
            '.mantine-Input-wrapper': {
              boxShadow: `0 0 0 ${sizes.border.md} ${colors.success[0]} inset`,
            },
            '.mantine-InputWrapper-error': {
              color: colors.success[0],
            },
            '.mantine-Input-rightSection': { borderColor: colors.success[0] },
          },
          '&[data-error]': {
            '.mantine-Input-wrapper': {
              boxShadow: `0 0 0 ${sizes.border.md} ${colors.error[0]} inset`,
            },
            '.mantine-InputWrapper-error': {
              color: colors.error[0],
            },
            '.mantine-Input-rightSection': { borderColor: colors.error[0] },
          },
        } as CSSObject,
        label: {
          color: fontColor,
          fontSize: fontSizes.md,
          marginBottom: sizes.gap.md,
        } as CSSObject,
        icon: {
          width: sizes.icon.md,
          paddingLeft: sizes.padding.md,
          color: iconColor,
        } as CSSObject,
        error: {
          lineHeight: 'normal',
          fontSize: fontSizes.sm,
          fontFamily: fontFamilies.bold,
          marginTop: sizes.gap.md,
          color: colors.text[1],
          // color,
        } as CSSObject,
        wrapper: {
          borderRadius: radius.sm,
          boxShadow: `0 0 0 ${sizes.border.md} ${colors.actions[0]} inset`,
          backgroundColor: 'unset',
        } as CSSObject,
      },
      inputStyles: {
        color: fontColor,
        border: 'none',
        fontSize: fontSizes.md,
        lineHeight: 'normal',
        height: 'fit-content',
        background: 'unset',
        paddingTop: sizes.padding.md,
        paddingBottom: sizes.padding.md,
        paddingLeft: sizes.padding.md,
        '::placeholder': { color: placeholderFontColor },
        ':focus': { boxShadow: getOutlineFocused({ colors, sizes }) },
        ':active': { boxShadow: getOutlinePressed({ colors, sizes }) },
        ':disabled': {
          opacity: 'unset',
          boxShadow: 'unset',
          backgroundColor: 'unset',
          '::placeholder': { color: disabledColor },
        },
        '&[data-invalid]': {
          color: fontColor,
          '::placeholder': { color: placeholderFontColor },
        },
        '&[data-with-icon]': {
          paddingLeft: `calc(${sizes.icon.md} + ${sizes.padding.md} + ${sizes.gap.md})`,
        },
      } as CSSObject,
      clearButtonStyle: {
        border: 'unset',
        height: 'unset',
        width: 'unset',
        minHeight: 'unset',
        minWidth: 'unset',
        color: iconColor,
        ':focus': {
          outline: 'none',
          boxShadow: getOutline({
            color: iconColor,
            gap: sizes.border.md,
            width: sizes.border.md,
            backgroundColor: colors.background[0],
          }),
        },
        ':hover': { '& svg': { color: hoverIconColor, backgroundColor: hoverIconBackgroundColor } },
        ':active': { transform: 'unset', outline: 'none', backgroundColor: hoverBackgroundColor },
      } as CSSObject,
    }
  }, [theme])
}
