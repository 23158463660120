import React, { SVGProps } from 'react'

export const Sort = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      stroke='currentColor'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 6L7 19' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 15L7 19L3 15' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='12' y1='7' x2='19' y2='7' strokeWidth='2' strokeLinecap='round' />
      <line x1='14' y1='11' x2='19' y2='11' strokeWidth='2' strokeLinecap='round' />
      <line x1='16' y1='15' x2='19' y2='15' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}
