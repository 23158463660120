// eslint-disable-next-line no-restricted-imports
import {
  Group,
  MantineNumberSize,
  Switch as MantineSwitch,
  SwitchGroupProps as MantineSwitchGroupProps,
  Stack,
} from '@mantine/core'
import React from 'react'
import { InputError } from '../InputWrapper'
import { useInlineInputGroupThemeStyles } from '../inline-inputs.helpers'
import { getAttributes } from '../inputs'
export type SwitchGroupProps = Omit<
  MantineSwitchGroupProps,
  'styles' | 'error' | 'size' | 'description'
> &
  InputError & { orientation?: 'vertical' | 'horizontal'; spacing?: MantineNumberSize }

export const SwitchGroup = ({
  children,
  error,
  warning,
  success,
  orientation = 'vertical',
  explanation,
  spacing = 'sm',
  ...props
}: SwitchGroupProps) => {
  const Wrapper = orientation === 'vertical' ? Stack : Group
  const { styles } = useInlineInputGroupThemeStyles()

  return (
    <MantineSwitch.Group
      error={error ?? success ?? warning ?? explanation}
      {...props}
      wrapperProps={getAttributes({
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      styles={styles}
    >
      <Wrapper spacing={spacing}>{children}</Wrapper>
    </MantineSwitch.Group>
  )
}
