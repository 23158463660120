import { PaymentPlan } from '.'

export const isActivePaymentPlan = (paymentPlan: PaymentPlan) => {
  const { paymentDates, canceledAt } = paymentPlan

  const hasPaymentsRemaining = paymentDates.some(
    paymentDate => new Date(paymentDate).getTime() > new Date().getTime(),
  )

  const isNotCanceled = !canceledAt
  return hasPaymentsRemaining && isNotCanceled
}

export const isMostRecentPaymentPlan = ({
  paymentPlan,
  paymentPlans,
}: {
  paymentPlan: PaymentPlan
  paymentPlans: PaymentPlan[]
}) => {
  if (paymentPlans.length === 0) {
    return false
  }

  const mostRecentDate = new Date(
    Math.max(...paymentPlans.map(p => new Date(p.createdAt).getTime())),
  )
  const createdAt = new Date(paymentPlan.createdAt)
  return createdAt.getTime() === mostRecentDate.getTime()
}

export const getCurrentPaymentPlan = ({ paymentPlans }: { paymentPlans: PaymentPlan[] }) => {
  if (paymentPlans.length === 0) {
    return null
  }

  return paymentPlans
    .filter(isActivePaymentPlan)
    .find(paymentPlan => isMostRecentPaymentPlan({ paymentPlan, paymentPlans }))
}
