import { useState } from 'react'

export function useCarousel<T extends readonly K[], K extends string>(items: T) {
  const [current, setCurrent] = useState(0)
  const { length } = items

  const next = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  return {
    next,
    prev,
    current: items[current] as T[number],
    step: current + 1,
    total: length,
  }
}
