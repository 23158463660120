// eslint-disable-next-line no-restricted-imports
import { Box, Group } from '@mantine/core'
import React, { PropsWithChildren } from 'react'
import { Text, TextProps } from '../typography'

export const TimelineCardTitle = ({
  title,
  children,
  ...props
}: PropsWithChildren<{ title: string } & TextProps>) => (
  <Group spacing='sm' noWrap>
    <Box className='timeline-card-title' sx={({ radius }) => ({ borderRadius: radius.sm })}>
      <Text lineClamp={1} bold {...props}>
        {title}
      </Text>
    </Box>
    {children}
  </Group>
)
