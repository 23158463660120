import { phone } from '@shared/utils'
import React, { PropsWithChildren } from 'react'
import { TextInput, TextInputProps } from '.'

export type PhoneInputProps = Omit<
  TextInputProps,
  'rightSection' | 'rightSectionProps' | 'rightSectionWidth' | 'placeholder' | 'formatter'
>

export const PhoneInput = (props: PropsWithChildren<PhoneInputProps>) => {
  const formatter = (value: string) => phone(value).formatted
  return (
    <TextInput
      type='tel'
      autoComplete='tel'
      formatter={formatter}
      placeholder='+1 (XXX) XXX - XXXX'
      {...props}
    />
  )
}
