export type OnPageTokenQuery<T> = (arg: { nextPageToken: string | null }) => Promise<T>
export type OnPageTokenCallback<T> = (arg: {
  response: T
  pageNumber: number
}) => Promise<{ nextPageToken: string | null }>

export const onPageToken = async <T>(
  query: OnPageTokenQuery<T>,
  callback: OnPageTokenCallback<T>,
) => {
  let pageNumber = 1
  let token = null

  let response = await query({
    nextPageToken: token,
  })

  const { nextPageToken } = await callback({
    response,
    pageNumber,
  })

  token = nextPageToken

  do {
    response = await query({
      nextPageToken: token,
    })

    pageNumber = pageNumber + 1

    const { nextPageToken } = await callback({
      response,
      pageNumber,
    })

    token = nextPageToken
  } while (token)
}
