import { date } from '@shared/utils'
import React, { PropsWithChildren } from 'react'
import { TextInput, TextInputProps } from './TextInput'

export type DateInputProps = Omit<
  TextInputProps,
  'rightSection' | 'rightSectionProps' | 'rightSectionWidth' | 'placeholder' | 'formatter'
>

export const DateInput = (props: PropsWithChildren<DateInputProps>) => {
  const formatter = (value: string) => date(value).formatted

  return (
    <TextInput
      inputMode='numeric'
      pattern='[0-9]*'
      formatter={formatter}
      placeholder='MM/DD/YYYY'
      {...props}
    />
  )
}
