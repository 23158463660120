import { FormContentSchema, FormContentType } from '../form/formContentSchema'
import { FormFieldSchema } from '../form/formFieldSchema'
import { FormFieldType } from '../form/formFieldType'
import { PatientFormTaskType } from './patientTasks'
import { TaskPayload } from './taskFields'

export type TaskFormStep<T extends PatientFormTaskType> = {
  name: string
  content: (FormFieldSchema<TaskPayload<T>, FormFieldType> | FormContentSchema<FormContentType>)[]
}

export type TaskForm<T extends PatientFormTaskType = PatientFormTaskType> = {
  title: string
  summary: string
  type: T
  steps: TaskFormStep<T>[]
}
