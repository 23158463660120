// eslint-disable-next-line no-restricted-imports
import {
  Group,
  Checkbox as MantineCheckbox,
  CheckboxGroupProps as MantineCheckboxGroupProps,
  MantineNumberSize,
  Stack,
} from '@mantine/core'
import React from 'react'
import { InputError } from '../InputWrapper'
import { useInlineInputGroupThemeStyles } from '../inline-inputs.helpers'
import { getAttributes } from '../inputs'

export type CheckboxGroupProps = Omit<
  MantineCheckboxGroupProps,
  'error' | 'size' | 'styles' | 'description'
> &
  InputError & { orientation?: 'vertical' | 'horizontal'; spacing?: MantineNumberSize }

export const CheckboxGroup = ({
  children,
  error,
  warning,
  success,
  orientation = 'vertical',
  explanation,
  spacing = 'sm',
  ...props
}: CheckboxGroupProps) => {
  const { styles } = useInlineInputGroupThemeStyles()
  const Wrapper = orientation === 'vertical' ? Stack : Group

  return (
    <MantineCheckbox.Group
      error={error ?? success ?? warning ?? explanation}
      {...props}
      wrapperProps={getAttributes({
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      styles={styles}
    >
      <Wrapper spacing={spacing}>{children}</Wrapper>
    </MantineCheckbox.Group>
  )
}
