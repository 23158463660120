import { dayjs } from '@shared/utils'
import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Grid, Stack, useMantineTheme } from '@mantine/core'
import { InputError, InputWrapper } from '../InputWrapper'
import { Radio, RadioGroup } from '../radio'
import { Text } from '../typography'
import { DailyCalendarLoadingGroup } from './DailyCalendarRadioGroup'

type DayColumnProps = {
  date: string
  slots: string[]
  tz: string
  onChange: (val: string) => void
  value: string
  loading: boolean
} & InputError

export const DayColumn = (props: DayColumnProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const dayColor = colors.text[1]

  const date = dayjs(props.date)

  if (!date.isValid()) {
    return null
  }

  const weeksDiff = date.diff(dayjs().startOf('week'), 'weeks')

  let datePrefix = ''
  if (weeksDiff === 0) {
    datePrefix = `This week—`
  } else if (weeksDiff === 1) {
    datePrefix = `Next week—`
  } else {
    datePrefix = `In ${weeksDiff} weeks—`
  }

  const dateTag = `${datePrefix}${date.isToday() ? 'Today' : date.format('ddd')}`

  return (
    <Stack spacing='sm'>
      <Text align='center' size='xs' color={dayColor}>
        {dateTag}
      </Text>
      <Text
        bold
        align='center'
        size='xs'
        color={props.slots.length > 0 || props.loading ? colors.text[0] : colors.actions[2]}
      >
        {date.format('MMM D YYYY').toUpperCase()}
      </Text>
      {props.loading ? (
        <DailyCalendarLoadingGroup />
      ) : (
        <RadioGroup
          error={Boolean(props.error)}
          warning={Boolean(props.warning)}
          success={Boolean(props.success)}
          value={props.value}
          onChange={props.onChange}
        >
          {props.slots.map(slot => (
            <Radio
              value={slot}
              label={
                <Text ff='inherit' align='center'>
                  {dayjs(slot)
                    .tz(props.tz ?? undefined)
                    .format('h:mma z')}
                </Text>
              }
              key={slot}
              iconless
            />
          ))}
        </RadioGroup>
      )}
    </Stack>
  )
}

type MultiCalendarRadioGroupProps = {
  data: DateWithSlots[]
  value: string
  onChange: (val: string) => void
  tz: string
} & InputError

export type DateWithSlots = {
  date: string
  slots: string[]
  loading: boolean
}

export const MultiCalendarRadioGroup = ({ data, ...props }: MultiCalendarRadioGroupProps) => {
  return (
    <InputWrapper
      error={props.error}
      warning={props.warning}
      success={props.success}
      explanation={props.explanation}
    >
      <Grid columns={5} gutter='sm'>
        <Grid.Col span={1}>
          <DayColumn
            {...props}
            date={data[0]?.date ?? ''}
            slots={data[0]?.slots ?? []}
            loading={Boolean(data[0]?.loading)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <DayColumn
            {...props}
            date={data[1]?.date ?? ''}
            slots={data[1]?.slots ?? []}
            loading={Boolean(data[1]?.loading)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <DayColumn
            {...props}
            date={data[2]?.date ?? ''}
            slots={data[2]?.slots ?? []}
            loading={Boolean(data[2]?.loading)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <DayColumn
            {...props}
            date={data[3]?.date ?? ''}
            slots={data[3]?.slots ?? []}
            loading={Boolean(data[3]?.loading)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <DayColumn
            {...props}
            date={data[4]?.date ?? ''}
            slots={data[4]?.slots ?? []}
            loading={Boolean(data[4]?.loading)}
          />
        </Grid.Col>
      </Grid>
    </InputWrapper>
  )
}
