// eslint-disable-next-line no-restricted-imports
import { useMantineTheme } from '@mantine/core'
import React, { SVGAttributes } from 'react'

export const OLogo = (props: SVGAttributes<SVGElement>) => {
  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 16C3.58174 16 0 12.4183 0 8C0 3.58172 3.58174 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 1.1724C4.91265 1.1724 2.4098 4.22913 2.4098 8C2.4098 11.7709 4.91265 14.8276 8 14.8276C11.0873 14.8276 13.5902 11.7709 13.5902 8C13.5902 4.22913 11.0873 1.1724 8 1.1724Z'
        fill={colors.actions[0]}
      />
    </svg>
  )
}
