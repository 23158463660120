import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Flex, Tooltip as MantineTooltip, TooltipProps as MantineTooltipProps } from '@mantine/core'

export type TooltipProps = Omit<MantineTooltipProps, 'styles' | 'withArrow'> & {
  native?: boolean
  top?: number
}

export const Tooltip = ({ children, native = false, top, ...props }: TooltipProps) => {
  return (
    <MantineTooltip
      {...props}
      styles={({ other: { colors, fontFamilies, sizes }, fontSizes }) => ({
        tooltip: {
          backgroundColor: colors.background[6],
          borderColor: colors.background[0],
          borderWidth: sizes.border.md,
          fontFamily: fontFamilies.bold,
          fontSize: fontSizes.xs,
          color: colors.text[3],
          paddingLeft: sizes.gap.md,
          paddingRight: sizes.gap.md,
          paddingTop: sizes.gap.sm,
          paddingBottom: sizes.gap.sm,
          // Override tooltip position
          ...(top ? { top: `${top}px!important` } : {}),
        },
      })}
    >
      {native ? children : <Flex>{children}</Flex>}
    </MantineTooltip>
  )
}
