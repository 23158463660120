import { ByEntity, DatabaseMetadata } from '.'

export type PatientOperationType =
  | 'transfer-field'
  | 'transfer-subcollection'
  | 'transfer-collection'
  | 'delete-subcollection'
  | 'delete-collection'
  | 'update-subcollection-document'
  | 'update-collection-document'

type PatientOperationObject<T extends PatientOperationType = PatientOperationType> =
  T extends 'transfer-field'
    ? {
        toPatientId: string
        field: string
        before: object
        after: object
      }
    : T extends 'transfer-subcollection'
    ? {
        toPatientId: string
        subcollection: string
        data: Record<string, object>
      }
    : T extends 'transfer-collection'
    ? {
        toPatientId: string
        collection: string
        data: Record<string, object>
      }
    : T extends 'delete-subcollection'
    ? {
        subcollection: string
        data: Record<string, object>
      }
    : T extends 'delete-collection'
    ? {
        collection: string
        data: Record<string, object>
        related?: Record<string, Record<string, object>>
      }
    : T extends 'update-collection-document'
    ? {
        collection: string
        documentId: string
        before: object
        after: object
      }
    : T extends 'update-subcollection-document'
    ? {
        subcollection: string
        documentId: string
        before: object
        after: object
      }
    : void

export type PatientOperationModel<T extends PatientOperationType = PatientOperationType> = {
  type: T
  patientId: string
  performedBy: ByEntity
  performedById: string | null
  reverted: boolean
  revertedBy: ByEntity | null
  revertedById: string | null
  // https://firebase.google.com/docs/firestore/ttl
  ttl: string
} & PatientOperationObject<T>

export type PatientOperation<T extends PatientOperationType = PatientOperationType> =
  PatientOperationModel<T> & DatabaseMetadata

export function isPatientOperation<T extends PatientOperationType>(
  operation: PatientOperation | PatientOperation<T>,
  type: T,
): operation is PatientOperation<T> {
  return operation.type === type
}
