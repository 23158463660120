export const RoiStatusList = [
  'Active',
  'Under review',
  'Needs changes',
  'Expired',
  'Revoked',
  /** For ROIs started from the emergency contact task */
  'In progress',
] as const

export type ReleaseOfInformationStatus = (typeof RoiStatusList)[number]

/** "Other" is an ROI form option for information type and can have any string value */
export const ReleaseOfInformationTypeMap = {
  CompleteMedicalRecord: 'Complete medical record',
  DosageVerification: 'Dosage verification',
  TreatmentVerification: 'Treatment verification',
  BillingInformation: 'Billing information',
  ProgramTreatementAdherance: 'Program/treatment adherence',
  UrineScreenSummary: 'Urine screen summary',
  MedicalEmergencyResponseInformation: 'Medical emergency response information',
  AppointmentsAndScheduling: 'Appointments and scheduling',
  Other: 'Other',
} as const

/** "Other" is an ROI form option for disclosure purpose and can have any string value */
export const ReleaseOfInformationDisclosurePurposeMap = {
  CoordinationOfCare: 'Coordination of care',
  Other: 'Other',
} as const

export type ReleaseOfInformationChangeRequest = {
  date: string
  /** Human-readable notes describing the changes requested */
  changesRequested: string
}
