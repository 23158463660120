// eslint-disable-next-line no-restricted-imports
import { Textarea as MantineTextarea, TextareaProps as MantineTextareaProps } from '@mantine/core'
import React from 'react'
import { InputError } from '../InputWrapper'
import { Formatter, useInputThemeStyles, useFormatter, getAttributes } from './inputs.helpers'

export type TextareaProps = Omit<
  MantineTextareaProps,
  'onChange' | 'error' | 'variant' | 'description'
> &
  InputError & {
    formatter?: Formatter
    onChange?: (value: string) => void
  }

export const Textarea = ({
  error,
  warning,
  success,
  disabled = false,
  onChange,
  value,
  formatter,
  rightSection,
  rightSectionWidth,
  explanation,
  minRows = 1,
  maxRows = 5,
  ...props
}: TextareaProps) => {
  const { formatValue, onChangeFormat } = useFormatter({ formatter, onChange })

  const {
    styles,
    inputStyles,
    theme: {
      other: { sizes },
    },
  } = useInputThemeStyles()

  return (
    <MantineTextarea
      {...props}
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      error={error ?? success ?? warning ?? explanation}
      // Default height is 5 lines
      minRows={props.autosize ? minRows : 5}
      maxRows={maxRows}
      onChange={onChangeFormat}
      disabled={disabled}
      value={formatValue(value)}
      rightSection={rightSection}
      styles={{
        rightSection: { width: 'unset', marginRight: sizes.padding.md },
        input: {
          ...inputStyles,
          // Fixes issue with Textarea overriding padding
          paddingTop: `${sizes.padding.md}!important`,
          paddingBottom: `${sizes.padding.md}!important`,
          paddingLeft: `${sizes.padding.md}!important`,
          paddingRight: `${
            rightSection
              ? `calc(${rightSectionWidth ?? sizes.icon.md} + ${sizes.padding.md} + ${
                  sizes.gap.md
                })`
              : sizes.padding.md
          }!important`,
        },
        ...styles,
      }}
    />
  )
}
