export type DiagnosisChronicity = 'acute' | 'chronic'
export type DiagnosisProgression = 'stable' | 'exacerbating'

export type MentalHealthEvaluationSaveRequest = {
  isEvaluated: 'yes' | 'no' | null
  diagnoses?: MentalHealthEvaluationDiagnosis[]
  reasonForNoEvaluation?: ReasonForNoEvaluation | null
  reasonForNoEvaluationOther?: string | null
}

export type MentalHealthEvaluationDiagnosis = {
  code: string
  chronicity: DiagnosisChronicity | null
  progression: DiagnosisProgression | null
  comment: string
}

export type ReasonForNoEvaluation =
  | 'Insufficient time'
  | 'Forgot, will assess next visit'
  | 'Focused on other conditions for this visit'
  | 'Other'
