export type ZoomOAuthResponse = {
  access_token: string
  token_type: 'bearer'
  expires_in: number
  scope: string
}

export type ZoomMeetingDetails = {
  /*
   * Meeting ID: Unique identifier of the meeting itself "long" format(represented as int64 data type in JSON),
   * also known as the meeting number. If this is a recurring meeting, it will have the same id each occurrence.
   */
  id: number
  // UUID for a distinct instance of the meeting, whether it is recurring or not.
  uuid: string
  host_email: string
  // URL for host to start and join the meeting. This URL should ONLY be shared with the meeting host. Do not send this to the patient.
  start_url: string
  // A generic join URL with no authentication
  join_url: string
  topic: string
  created_at: string
  settings: {
    authentication_exception: {
      name: string
      email: string
      // This is the special auth-ed url with a token that is associated with the patient's email and name
      join_url: string
    }[]
  }
  // Meeting password. Password may only contain the following characters: [a-z A-Z 0-9 @ - _ * !]
  password: string
}
export type ZoomPastMeetingDetails = {
  uuid: string
  id: number
  host_id: string
  type: number
  topic: string
  user_name: string
  user_email: string
  start_time: string
  end_time: string
  duration: number
  total_minutes: number
  participants_count: number
  dept: string
  source: string
}
