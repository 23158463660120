/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-restricted-imports
import { DefaultMantineColor, MantineSizes, MantineThemeOverride, Tuple } from '@mantine/core'

/**
 * Mantine colors must be in a list of length 10.
 */
export const createHomogenousColorList = (hexColor: `#${string}`): Tuple<string, 10> => {
  return [
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
    hexColor,
  ]
}

export type HexColor = `#${string}`

export type Colors = {
  success: Tuple<HexColor, 2>
  warning: Tuple<HexColor, 2>
  error: Tuple<HexColor, 2>
  actions: Tuple<HexColor, 4>
  actionText: HexColor
  text: Tuple<HexColor, 4>
  background: Tuple<HexColor, 8>
}

export type Fonts = {
  regular: string
  bold: string
  pro: string
}

/**
 * @TODO these colors names are not generic, only apply to Twilight.
 * Must refactor.
 */
type ExtendedCustomColors =
  | 'actions.0'
  | 'actions.1'
  | 'cream'
  | 'success'
  | 'error'
  | 'warning'
  | DefaultMantineColor

// https://mantine.dev/theming/extend-theme/#typescript
export type ThemeExtendedCustomColors = Record<ExtendedCustomColors, Tuple<string, 10>>
export type ThemeColors = Record<ExtendedCustomColors, Tuple<string, 10>>
export type ThemeOtherFontFamilies = Fonts
export type ThemeOtherColors = Colors

export type Theme = 'twilight' | 'daybreak' | 'equinox'

// https://mantine.dev/theming/extend-theme/#typescript
declare module '@mantine/core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface MantineThemeColorsOverride {
    colors: ThemeColors
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface MantineThemeOther {
    fontFamilies: ThemeOtherFontFamilies
    colors: ThemeOtherColors
    themeName: Theme
    sizes: {
      // Specific configuration for iconbutton size
      iconbutton: Omit<MantineSizes, 'xl'>
      // Specific configuration for button height
      button: Omit<MantineSizes, 'lg' | 'xl'>
      // Specific configuration for input height
      input: Omit<MantineSizes, 'xs' | 'sm' | 'lg' | 'xl'>
      // Gap refers to spacing between components in PX
      gap: Omit<MantineSizes, 'xs'>
      // Padding refers to component padding in PX
      padding: MantineSizes
      // Icon refers to icon sizes in PX
      icon: Omit<MantineSizes, 'xs'>
      // Outline refers to component outlines (focus) in PX
      outline: Omit<MantineSizes, 'xs' | 'xl'>
      // Border refers to component borders in PX
      border: Omit<MantineSizes, 'xs' | 'sm' | 'lg' | 'xl'>
    }
  }
}

export const baseTheme: MantineThemeOverride = {
  cursorType: 'pointer',
  white: '#FFFFFF',
  black: '#000000',
  defaultRadius: 'sm',
  focusRing: 'always',
  primaryColor: 'primary',
  breakpoints: {
    xs: '31.25rem',
    // 735px and under is mobile
    sm: '46rem',
    md: '62.5rem',
    lg: '75rem',
    xl: '87.5rem',
  },
}
