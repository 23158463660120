// eslint-disable-next-line no-restricted-imports
import { AspectRatio } from '@mantine/core'
import {
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  Dropzone as MantineDropzone,
  DropzoneProps as MantineDropzoneProps,
  PDF_MIME_TYPE,
} from '@mantine/dropzone'
import React, { ReactNode } from 'react'
import type { FileRejection } from 'react-dropzone'
import { InputError } from './InputWrapper'
import { getAttributes, getOutlineFocused } from './inputs'

export { FileRejection, IMAGE_MIME_TYPE, MIME_TYPES, PDF_MIME_TYPE }

export type DropzoneProps = {
  maxSizeMB: number
  preview?: boolean
  children?: ReactNode
  ratio?: number
  borderStyle?: 'solid' | 'dashed'
} & InputError &
  Omit<
    MantineDropzoneProps,
    'maxSize' | 'styles' | 'children' | 'nonce' | 'onResize' | 'onResizeCapture'
  >

export const Dropzone = React.forwardRef<HTMLDivElement, DropzoneProps>(
  (
    {
      maxSizeMB,
      warning,
      success,
      error,
      children,
      borderStyle = 'dashed',
      preview = false,
      disabled = false,
      loading = false,
      // eslint-disable-next-line no-magic-numbers
      ratio = 148 / 93,
      ...props
    },
    ref,
  ) => {
    return (
      <MantineDropzone
        {...props}
        {...getAttributes({
          ['data-disabled']: disabled,
          ['data-preview']: preview,
          ['data-error']: Boolean(error),
          ['data-warning']: Boolean(warning),
          ['data-success']: Boolean(success),
        })}
        /* eslint-disable-next-line no-magic-numbers */
        maxSize={maxSizeMB * 1024 ** 2}
        disabled={disabled || preview}
        loading={loading}
        styles={({ other: { colors, sizes } }) => ({
          root: {
            borderWidth: sizes.border.md,
            borderColor: colors.actions[0],
            borderStyle,
            backgroundColor: 'unset',
            color: colors.text[0],
            padding: 'unset',
            '&[data-reject]': { borderColor: colors.error[0], borderStyle: 'solid' },
            '&[data-warning]': { borderColor: colors.warning[0], borderStyle: 'solid' },
            '&[data-success]': { borderColor: colors.success[0], borderStyle: 'solid' },
            '&[data-error]': { borderColor: colors.error[0], borderStyle: 'solid' },
            '&[data-accept]': { borderColor: colors.actions[0], borderStyle: 'solid' },
            '&[data-loading]': { '&:hover': { backgroundColor: 'unset' } },
            ':hover': { backgroundColor: `${colors.actions[2]}30` },
            ':focus': { boxShadow: getOutlineFocused({ sizes, colors }) },
            '&[data-preview]': { backgroundColor: 'unset', cursor: 'unset' },
            '&[data-disabled]': {
              borderColor: colors.actions[2],
              backgroundColor: 'unset',
              boxShadow: 'unset',
              cursor: 'not-allowed',
              '.mantine-Text-root': { color: colors.actions[2] },
            },
          },
          inner: { padding: sizes.padding.xs, pointerEvents: 'auto' },
        })}
      >
        <AspectRatio ref={ref} ratio={ratio}>
          {children}
        </AspectRatio>
      </MantineDropzone>
    )
  },
)

Dropzone.displayName = 'Dropzone'
