import React, { SVGAttributes } from 'react'

export const MyOpheliaWordmark = (props: SVGAttributes<SVGElement>) => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 1328 307'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1322.4 224.11C1325.33 224.11 1327.93 226.06 1327.93 229.31C1327.93 235.49 1317.85 247.51 1303.55 247.51C1292.83 247.51 1280.8 241.01 1278.85 221.83C1278.85 221.18 1278.85 220.2 1278.2 220.2C1277.55 220.2 1277.55 220.53 1276.9 221.5C1265.2 239.05 1245.05 247.5 1224.89 247.5C1193.36 247.5 1174.18 228 1174.18 205.57C1174.18 192.57 1180.68 178.92 1194.33 170.14C1211.23 158.76 1231.39 155.19 1249.26 146.74C1262.26 140.89 1276.56 131.46 1276.56 113.91C1276.56 98.31 1265.83 87.26 1248.28 87.26C1228.78 87.26 1218.7 99.94 1210.58 111.31C1204.08 120.41 1203.1 126.26 1195.95 126.26C1188.15 126.26 1186.85 118.78 1186.85 110.33C1186.85 101.88 1188.47 91.48 1192.37 86.6C1201.15 75.22 1227.47 70.67 1246 70.67C1287.61 70.67 1309.06 94.07 1309.06 126.25C1309.06 151.6 1308.08 181.51 1308.08 207.51C1308.08 218.89 1311.98 224.09 1318.48 224.09H1322.38L1322.4 224.11ZM1276.57 152.28C1276.57 151.63 1276.25 150.98 1275.59 150.98C1274.93 150.98 1274.62 151.3 1273.31 152.28C1261.93 161.05 1240.48 166.58 1224.88 176.98C1213.18 184.78 1208.63 194.86 1208.63 203.96C1208.63 216.96 1217.4 228.66 1234.63 228.66C1247.31 228.66 1261.93 221.51 1269.73 205.58C1276.23 191.93 1276.56 177.63 1276.56 157.8V152.27L1276.57 152.28Z'
      fill='currentColor'
    />
    <path
      d='M860.87 186.73C860.87 203.63 861.85 215.98 865.75 222.16C871.27 230.61 881.68 231.91 881.68 237.76C881.68 242.31 877.46 243.94 872.58 243.94H817.98C813.1 243.94 808.88 242.31 808.88 237.76C808.88 231.26 819.93 230.94 825.13 220.86C828.38 214.69 829.35 202.98 829.35 186.73V136.03C829.35 107.43 814.4 95.4 795.22 95.4C785.47 95.4 775.07 98.97 767.27 106.78C754.92 119.13 751.99 136.68 751.99 186.74C751.99 203.32 752.96 215.34 756.54 221.52C762.07 230.95 772.47 231.92 772.47 237.77C772.47 242.32 768.57 243.95 763.7 243.95H709.74C704.86 243.95 700.96 242.32 700.96 237.77C700.96 230.95 712.66 230.62 717.54 220.22C720.47 213.72 721.44 202.67 721.44 186.74V53.47C721.44 43.07 721.12 37.22 718.19 32.99C712.99 25.19 699.34 26.81 699.34 19.66C699.34 16.08 700.96 13.81 708.44 12.18C720.47 9.58 730.22 6.33 738.67 2.43C740.95 1.45 742.9 0.800003 744.85 0.800003C749.08 0.800003 752 3.72 752 9.9V98.96C752 99.94 752.33 100.59 752.97 100.59C753.61 100.59 753.95 99.94 754.6 98.96C764.67 84.01 782.88 70.68 807.25 70.68C825.78 70.68 841.05 78.48 849.83 90.83C856.98 100.58 860.88 112.28 860.88 135.04V186.72L860.87 186.73Z'
      fill='currentColor'
    />
    <path
      d='M1024.03 208.83C1024.03 217.93 999.33 247.51 956.1 247.51C906.37 247.51 877.12 208.51 877.12 163.98C877.12 116.53 910.92 70.7 961.3 70.7C1008.11 70.7 1027.61 108.73 1027.61 131.48C1027.61 151.31 1017.86 154.23 1001.93 154.56H913.85C911.57 154.56 910.27 155.54 910.27 157.81V159.43C910.27 193.56 929.77 224.11 964.23 224.11C996.08 224.11 1009.08 202.01 1016.88 202.01C1020.78 202.01 1024.03 204.28 1024.03 208.83ZM911.57 136.35C911.57 137.97 912.22 138.3 914.17 138.3H967.15C986 138.3 994.45 134.4 994.45 119.45C994.45 104.5 981.45 86.62 958.37 86.62C932.37 86.62 915.79 108.72 911.89 132.77C911.57 135.37 911.57 136.02 911.57 136.34'
      fill='currentColor'
    />
    <path
      d='M1078.64 220.21C1084.49 231.59 1095.22 230.94 1095.22 237.76C1095.22 242.31 1090.99 243.94 1086.12 243.94H1031.84C1026.96 243.94 1023.06 242.32 1023.06 237.76C1023.06 230.93 1034.44 230.61 1039.64 220.21C1042.89 213.71 1043.54 202.98 1043.54 187.06V59.64C1043.54 45.66 1043.54 38.19 1040.29 32.99C1038.34 29.74 1034.44 27.79 1029.56 26.16C1025.01 24.53 1021.76 22.91 1021.76 19.66C1021.76 16.41 1023.06 13.81 1030.86 12.18C1042.89 9.58 1051.99 6.98 1061.74 2.75C1064.02 1.77 1065.96 1.12 1067.92 1.12C1071.82 1.12 1074.74 3.72 1074.74 9.57V187.04C1074.74 203.29 1075.39 213.69 1078.64 220.19'
      fill='currentColor'
    />
    <path
      d='M1158.26 220.21C1164.11 231.59 1174.84 230.94 1174.84 237.76C1174.84 242.31 1170.61 243.94 1165.74 243.94H1111.46C1106.58 243.94 1102.68 242.32 1102.68 237.76C1102.68 230.93 1114.06 230.61 1119.26 220.21C1122.51 213.71 1123.16 202.98 1123.16 187.06V129.2C1123.16 115.22 1123.16 107.75 1119.91 102.55C1117.96 99.3 1114.06 97.35 1109.19 95.72C1104.64 94.09 1101.39 92.47 1101.39 89.22C1101.39 85.97 1102.69 83.37 1110.49 81.75C1122.52 79.15 1131.62 76.55 1141.37 72.32C1143.65 71.34 1145.6 70.69 1147.54 70.69C1151.44 70.69 1154.37 73.29 1154.37 79.14V187.05C1154.37 203.3 1155.02 213.7 1158.27 220.2M1161.19 23.07C1161.19 35.82 1150.86 46.15 1138.11 46.15C1125.36 46.15 1115.03 35.82 1115.03 23.07C1115.03 10.32 1125.37 0 1138.11 0C1150.85 0 1161.19 10.33 1161.19 23.08'
      fill='currentColor'
    />
    <path
      d='M403.71 7.96C337.56 7.96 283.94 61.59 283.94 127.73C283.94 193.87 337.57 247.5 403.71 247.5C469.85 247.5 523.48 193.88 523.48 127.73C523.48 61.58 469.86 7.96 403.71 7.96ZM403.71 229.96C357.49 229.96 320.01 184.19 320.01 127.74C320.01 71.29 357.48 25.52 403.71 25.52C449.94 25.52 487.41 71.29 487.41 127.74C487.41 184.19 449.94 229.96 403.71 229.96Z'
      fill='currentColor'
    />
    <path
      d='M636.27 70.69C615.14 70.69 595.97 82.07 582.96 97.99C581.66 99.62 581.01 99.62 580.36 99.62C579.39 99.62 578.74 98.65 578.74 97.02V79.14C578.74 73.29 576.14 70.69 572.24 70.69C570.29 70.69 568.01 71.34 565.74 72.32C555.99 76.54 547.21 79.15 535.19 81.75C527.71 83.38 526.09 85.65 526.09 88.58C526.09 96.71 539.09 94.11 544.62 102.56C547.87 107.76 547.87 113.94 547.87 125.96V249.8C547.87 264.75 547.22 275.48 544.94 281.65C541.04 293.03 527.39 293.35 527.39 300.5C527.39 305.05 531.62 306.68 536.49 306.68H590.44C595.32 306.68 599.22 304.73 599.22 300.18C599.22 293.03 587.84 294 582.64 282.95C579.71 276.77 578.74 266.05 578.74 250.77V235.49C578.74 234.51 579.39 233.86 580.04 233.86C582.64 233.86 597.92 247.51 625.22 247.51C676.25 247.51 702.9 195.83 702.9 150.98C702.9 100.92 673 70.7 636.27 70.7M624.89 231.27C599.4 231.27 578.73 200.57 578.73 162.69C578.73 124.81 599.39 94.11 624.89 94.11C650.39 94.11 671.04 124.82 671.04 162.69C671.04 200.56 650.38 231.27 624.89 231.27Z'
      fill='currentColor'
    />
    <path
      d='M145.94 172.44V245.62H126.92V172.65C126.92 160.25 120.31 152.81 108.52 152.81C96.12 152.81 82.47 160.25 82.47 187.74V245.62H63.45V172.65C63.45 160.25 56.84 152.81 45.05 152.81C32.65 152.81 19.01 161.29 19.01 188.78V245.63H0V138.97H18.19L17.36 154.68C24.39 142.9 34.93 136.28 49.81 136.28C64.69 136.28 74.62 142.69 79.37 154.88C86.4 142.48 97.77 136.28 113.27 136.28C134.15 136.28 145.93 147.65 145.93 172.45L145.94 172.44Z'
      fill='#F1F1DE'
    />
    <path
      d='M256.32 138.96L206.71 266.5C200.3 282.83 192.45 287.79 177.36 287.79H165.58V271.87H175.5C185.21 271.87 188.94 268.77 193.28 257.61L198.24 244.59L155.24 138.96H175.91L203.61 212.55L205.88 225.37L236.68 138.96H256.32Z'
      fill='#F1F1DE'
    />
  </svg>
)
