import { useState } from 'react'

export const DEFAULT_LOAD_MORE_CHUNK_SIZE = 5

type UseLoadMoreResult = {
  displayedDataCount: number
  setDisplayedDataCount: (n: number) => void
  hasMoreData: boolean
  loadMore: () => void
}

/** Handles "load more" pattern for client-side data  */
export const useLoadMore = (
  dataCount: number,
  chunkSize = DEFAULT_LOAD_MORE_CHUNK_SIZE,
): UseLoadMoreResult => {
  const [displayedDataCount, setDisplayedDataCount] = useState(Math.min(dataCount, chunkSize))

  const setDisplayedDataCountWrapper = (n: number) => setDisplayedDataCount(Math.min(n, dataCount))

  const loadMore = () => {
    setDisplayedDataCount(Math.min(displayedDataCount + chunkSize, dataCount))
  }

  return {
    displayedDataCount,
    setDisplayedDataCount: setDisplayedDataCountWrapper,
    hasMoreData: dataCount > displayedDataCount,
    loadMore,
  }
}
