// eslint-disable-next-line no-restricted-imports
import { keyframes } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { Flex, MantineTheme, createStyles, useMantineTheme } from '@mantine/core'
import React, { SVGProps } from 'react'

const PULSE_ANIMATION_THREE_QUARTERS = 0.75
const PULSE_ANIMATION_ONE_QUARTER = 0.25
const PULSE_ANIMATION_ONE_TENTH = 0.1

const getPulseAnimation = ({
  theme,
  iconColor,
}: {
  theme: MantineTheme
  iconColor: `#${string}`
}) => {
  return keyframes('pulse', {
    '0%': {
      boxShadow: `0 0 0 ${theme.fn.rgba(iconColor, PULSE_ANIMATION_ONE_TENTH)}`,
    },
    '70%': {
      boxShadow: `0 0 0 ${theme.spacing.xs} ${theme.fn.rgba(
        iconColor,
        PULSE_ANIMATION_ONE_QUARTER,
      )}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${theme.fn.rgba(iconColor, PULSE_ANIMATION_THREE_QUARTERS)}`,
    },
  })
}

const useStyles = ({ variant }: { variant?: `#${string}` | undefined }) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const iconColor = variant || colors.error[0]

  return createStyles(theme => ({
    pulse: {
      borderRadius: '50rem',
      // eslint-disable-next-line no-magic-numbers
      boxShadow: `0 0 0 ${theme.fn.rgba(iconColor, PULSE_ANIMATION_ONE_TENTH)}`,
      animation: `${getPulseAnimation({ iconColor, theme })} 2s infinite`,
    },
  }))
}

type ConcentricCirclesProps = {
  variant?: `#${string}`
  pulse?: boolean
} & SVGProps<SVGSVGElement>

export const ConcentricCircles = ({ pulse, variant, ...props }: ConcentricCirclesProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const styles = useStyles({ variant })

  const iconColor = variant || colors.error[0]

  if (pulse && styles()) {
    return (
      <Flex className={styles().cx({ [styles().classes.pulse]: true })}>
        <svg
          width='6'
          height='6'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <circle cx='6' cy='6' r='6' fill={iconColor} />
        </svg>
      </Flex>
    )
  }
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='12' cy='12' r='6' fill={iconColor} />
      <circle cx='12' cy='12' r='8' stroke={iconColor} strokeOpacity='0.2' strokeWidth='8' />
    </svg>
  )
}
