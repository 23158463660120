/**
 * @deprecated
 * Support requests are no longer used.
 */

export const SUPPORT_REQUEST_SEVERITY = ['Urgent', 'High', 'Medium', 'Low'] as const

export type SupportRequestSeverity = (typeof SUPPORT_REQUEST_SEVERITY)[number]

export const EMR_SUPPORT_REQUEST_CATEGORIES = {
  COORDINATION_WITH_EXTERNAL_PROVIDER: 'Coordination with External Provider',
  PRIOR_AUTHORIZATION_NEEDED: 'Prior Authorization Needed',
  CHECK_IN_WITH_CARE_COORDINATION: 'Check-In with Care Coordination',
  TECH_SUPPORT: 'Tech Support',
  OTHER: 'Other',
  DRUG_SCREEN_SHIPMENT_FAILURE: 'Drug screen shipment failure',
  PEER_SUPPORT: 'Peer Support',
} as const

export type EMRSupportRequestCategory = keyof typeof EMR_SUPPORT_REQUEST_CATEGORIES

export const SUPPORT_REQUEST_CATEGORIES = {
  ...EMR_SUPPORT_REQUEST_CATEGORIES,
  /*
   * Ineligible and Discharge notes are created only via subscribers, they do not show up in the
   * EMR support request modal.
   */
  INELIGIBLE_NOTE: 'Ineligible note',
  DISCHARGE_NOTE: 'Discharge note',
} as const

export type SupportRequestCategory = keyof typeof SUPPORT_REQUEST_CATEGORIES
