import { Employee } from '.'

export type RemoteConfigEntries = {
  erx_prior_authorization_warning_employee_ids: Employee['oid'][]
  is_enrollment_panel_enabled: boolean
  is_custom_auto_confirm_enabled: boolean
  is_embedded_zoom_enabled: boolean
  omit_high_risk_slots: boolean
  use_supported_visit_types: boolean
  thank_you_note: boolean
}
