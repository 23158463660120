// eslint-disable-next-line no-restricted-imports
import { Progress as MantineProgress, ProgressProps as MantineProgressProps } from '@mantine/core'
import React from 'react'

export type ProgressProps = MantineProgressProps

const ONE_HUNDRED = 100

export const toPercentage = (val: number) => {
  return Math.floor(val * ONE_HUNDRED)
}

export const Progress = (props: ProgressProps) => {
  return (
    <MantineProgress
      sx={theme => ({
        backgroundColor: theme.other.colors.background[1],
      })}
      {...props}
    />
  )
}
