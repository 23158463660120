import { OpheliaRoute } from './utils'

export type KudosboardPost = {
  text: string
  hearts: number
  likes: number
}

export type WaitingList = {
  name: string
  email: string
}

export type BridgeApi = OpheliaRoute<
  'GET /posts',
  {
    req: never
    res: {
      posts: string[]
    }
  }
> &
  OpheliaRoute<
    'GET /posts/:id',
    { req: { params: { id: string } }; res: { post: KudosboardPost } }
  > &
  OpheliaRoute<'POST /posts/:id/heart', { req: { params: { id: string } }; res: void }> &
  OpheliaRoute<'POST /posts/:id/like', { req: { params: { id: string } }; res: void }> &
  OpheliaRoute<'POST /waiting-list', { req: { data: WaitingList }; res: void }> &
  OpheliaRoute<
    'GET /chatbox/status',
    {
      req: never
      res: {
        isAvailable: boolean
      }
    }
  >
